<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button @click="saveDrawing = true">{{ $t('Siguiente') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <div class="signature-block">
        <VueSignaturePad
          ref="drawingPad"
          :options="{
            onBegin: () => $refs.drawingPad.resizeCanvas(),
          }"
        />
      </div>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="saveDrawing"
    @didDismiss="saveDrawing = false"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="saveDrawing = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button :disabled="!drawing.nombre && drawing.descripcion" @click="processDrawing">{{ $t('Guardar') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-item>
        <ion-input :label="$t('Nombre')" label-placement='floating' v-model="drawing.nombre"></ion-input>
      </ion-item>

      <ion-item>
        <ion-textarea
          :autoGrow="true"
          v-model="drawing.descripcion"
          :label="$t('Observaciones')"
          label-placement="floating"
        ></ion-textarea>
      </ion-item>

    </ion-content>
  </ion-modal>

</template>

<script>
import { ref, getCurrentInstance } from 'vue';

import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonContent,
  IonItem,
  IonInput,
  IonTextarea,
} from '@ionic/vue';

import {
  closeCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons'

import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'MSetWoDrawing',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    workorder: {
      type: Object,
      required: true
    }
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonContent,
    IonItem,
    IonInput,
    IonTextarea,
  },

  computed: {},

  setup() {
    const gmao = useGmaoStore();

    const app = getCurrentInstance();

    return {
      drawing: ref({
        nombre: null,
        descripcion: null,
      }),
      saveDrawing: ref(false),

      // STORES
      gmao,
      app,

      // ICONS
      closeCircleOutline,
      checkmarkCircleOutline,
    };
  },

  methods: {

    processDrawing() {
      const { isEmpty, data } = this.$refs.drawingPad.saveSignature();
      if (!isEmpty) this.drawing.image = data;
      this.$emit('update:modelValue', false);
      this.saveDrawing = false;

      this.setDrawing(this.drawing);
    },

    setDrawing(drawing) {
      const blob = this.$base64toBlob(drawing.image.split(',')[1]);

      const formData = new FormData();

      formData.append('id_parte', this.workorder?.id || '');

      // TODO: PREGUNTAR POR NOMBRE/DESCRIPCION
      formData.append('nombre', this.drawing.nombre || '');
      formData.append('descripcion', this.drawing.descripcion || '');

      formData.append('drawing', blob);


      this.$axios
        .post(
          `/v2/users/actions.php?call=setWorkorderDrawing&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(() => {
          // IF OK
          this.$emit('done', true);
        })
    }
  },
};
</script>
<style lang="scss" scoped>
.signature-block {
  border: 1px solid black;
  width: 100%;
  height: calc(100% - 44px);
  background: white;
}
</style>