<template>
  <ion-modal :is-open="modelValue" @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Iniciar tiempos') }}</ion-title>

        <ion-buttons slot="end">
          <ion-button :disabled="isTypeMandatory" @click="setTimer()">{{ $t('Iniciar') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-label class="section-title">
        {{ $t('tecnicos') }}
      </ion-label>
      <ion-card class="custom-card">
        <ion-card-content>
          <!-- Subsección: Técnico Principal -->
          <ion-label class="subsection-title">
            {{ $t('main') }}
          </ion-label>
          <ion-item lines="none" class="main-technician">
            <template v-if='isTecnicosAdicionales'>
              <ion-checkbox slot="start" v-model="mainTechnician.checked" label-placement='end'><h3>{{ mainTechnician.nombre_completo }}</h3></ion-checkbox>
            </template>
            <template v-else>
              <ion-label>
                <h3>{{ mainTechnician.nombre_completo }}</h3>
              </ion-label>
            </template>
          </ion-item>

          <!-- Subsección: Técnicos Adicionales -->
          <template v-if='(!gmao.comportamientos?.tiempos_propio_tecnico && isTecnicosAdicionales)
            || isTecnicosAdicionales'>
            <div class="subsection-header">
              <ion-label class="subsection-title">
                {{ $t('additional') }}
              </ion-label>
              <ion-button class="ion-no-margin checkAll" size="small" fill="outline"
                @click="toggleMarkAll">
                <ion-icon :icon="checkmarkCircleOutline" slot="start"></ion-icon>
                {{ $t('marcar-todos') }}
              </ion-button>
            </div>
            <ion-list class="ion-no-padding">
              <ion-item v-for="(tech, i) in additionalTechnicians?.slice(0, tecnicos_cant)"
                :key="`Techn-${i}`" lines="none">
                <ion-checkbox slot="start" v-model="tech.checked" label-placement='end'><h3>{{ tech.nombre_completo }}</h3></ion-checkbox>
              </ion-item>
              <ion-button
                v-if="(tecnicos_cant <= additionalTechnicians.length) && additionalTechnicians.length > 4"
                class="ion-no-margin" style="display:flex;" size="small" fill="clear"
                color="tertiary" @click="loadMoreTecnicosAd(additionalTechnicians.length / 4 + 1)">
                <span>{{ $t('Ver más') }}</span>
              </ion-button>
            </ion-list>
          </template>
        </ion-card-content>
      </ion-card>

      <ion-label class="section-title">
        {{ $t('Tipo de tiempo') }} <template v-if="behaviourTypeTime">*</template>
      </ion-label>
      <template v-if="hourTypes.length">
        <ion-card class="custom-card">
          <ion-card-content>
            <ion-list class="ion-no-padding">
              <ion-radio-group v-model="selectedHourType.id">
                <ion-item v-for="(type, index) in hourTypes?.slice(0, tiposHora_cant)" :key="index"
                  lines="none">
                  <ion-radio slot="start" :value="type.id" label-placement="end">
                    <h3>{{ type.nombre }}</h3>
                    <!-- NOTE: Podemos obtener si son horas convenio para informar al técnico -->
                  </ion-radio>
                </ion-item>
              </ion-radio-group>
              <ion-button
                v-if="(tiposHora_cant <= hourTypes.length) && hourTypes.length > 4"
                class="ion-no-margin" style="display:flex;" size="small" fill="clear" color="tertiary"
                @click="loadMoreTiposHora(hourTypes.length / 4 + 1)">
                <span>{{ $t('Ver más') }}</span>
              </ion-button>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <template v-else>
        <ion-card class="custom-card">
          <ion-card-content>
            <ion-text>
              {{ $t('no_hour_types') }}
            </ion-text>
          </ion-card-content>
        </ion-card>
      </template>
    </ion-content>

  </ion-modal>
</template>

<script>
import { ref, watch } from 'vue';
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  // loadingController,
} from '@ionic/vue';

import { checkmarkCircleOutline } from 'ionicons/icons';

import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'MSetHora',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newHora: {
      required: true,
      type: Object,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    },

    additionalTechnicians: {
      required: false,
      type: Array,
    },

    hourTypes: {
      required: true,
      type: Array,
    },

    isMainTechnician: {
      required: true,
      type: Boolean,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonRadioGroup,
    IonRadio,
  },

  setup(props) {
    const gmao = useGmaoStore();
    const mainTechnician = gmao.user;
    const allChecked = ref(false);
    const selectedHourType = ref({});

    const tecnicos_cant = ref(0);
    const tecnicos_limit = ref(4);
    const tecnicos_page = ref(1);

    const tiposHora_cant = ref(0);
    const tiposHora_limit = ref(4);
    const tiposHora_page = ref(1);

    const behaviourTypeTime = +gmao.comportamientos?.campo_tipo_tiempo_obligatorio == 1;
    const isTypeMandatory = ref(false);
    

    // Watch para asignar el tipo por defecto al abrir el modal
    watch(
      () => props.modelValue,
      (newVal) => {
        if (newVal) {

          checkMandatoryType();
          loadMoreTecnicosAd();
          loadMoreTiposHora();
          if (props.isTecnicosAdicionales) {
            mainTechnician.checked = true;
          }
          const defaultType = props.hourTypes.find((type) => type.por_defecto === 1);
          if (defaultType) {
            selectedHourType.value = defaultType;
          }
        }
      }
    );

    watch(
      () => selectedHourType.value?.id,
      () => {
        checkMandatoryType();
      }
    );

    const checkMandatoryType = () => {
      isTypeMandatory.value = behaviourTypeTime && !+selectedHourType.value?.id;
    }

    const loadMoreTecnicosAd = async (page = +tecnicos_page.value) => {
      tecnicos_cant.value = page * +tecnicos_limit.value;

      // let loading = null;
      // if (+page > 1) {
      //   loading = await loadingController.create({
      //     message: t('cargando...'),
      //   });
      //   await loading.present();
      // }

      // await loading?.dismiss();
    }

    const loadMoreTiposHora = async (page = +tiposHora_page.value) => {
      tiposHora_cant.value = page * +tiposHora_limit.value;

      // let loading = null;
      // if (+page > 1) {
      //   loading = await loadingController.create({
      //     message: t('cargando...'),
      //   });
      //   await loading.present();
      // }

      // await loading?.dismiss();
    }

    const toggleMarkAll = () => {
      allChecked.value = !allChecked.value;
      props.additionalTechnicians.forEach((tech) => {
        tech.checked = allChecked.value;
      });
    };


    return {
      gmao,
      mainTechnician,
      checkmarkCircleOutline,
      selectedHourType,
      toggleMarkAll,
      allChecked,
      tecnicos_cant,
      tecnicos_limit,
      tecnicos_page,
      loadMoreTecnicosAd,

      tiposHora_cant,
      tiposHora_limit,
      tiposHora_page,
      loadMoreTiposHora,
      isTypeMandatory,
      behaviourTypeTime
    };
  },

  methods: {
    setTimer() {
      let tecnicos = [];

      if (this.isTecnicosAdicionales) {
        tecnicos = this.additionalTechnicians
          .filter((t) => +t.checked)
          .map((a) => a.id) || [];

        const selectedMainTech = this.mainTechnician.checked && this.mainTechnician
        selectedMainTech?.id && tecnicos.push(selectedMainTech.id);

        tecnicos = [...new Set(tecnicos)];

      } else {
        tecnicos.push(this.mainTechnician.id);
      }

      if (!tecnicos.length) {
        this.$openToastObject(
          this.$t('generic_timer_error'),
          this.$t('timer_error_missing_techs'),
          'danger',
          'bottom',
          '4000'
        );
        return;
      }

      const data = {
        users: tecnicos.toString(),
        tipo: this.selectedHourType,
      };

      this.$emit('done', data)
    },

  },
};
</script>

<style lang="scss" scoped>
ion-card {
  margin-bottom: 30px;
  margin-top: 18px;
  margin-inline: 0;
  border-radius: 12px;
  --ion-background-color: #fff;
  box-shadow: none
}

ion-content {
  --ion-background-color: #f6f6f6;
}

ion-item {
  margin-bottom: 0;
  &::part(native) {
    padding-left: 0;
  }
}


.section-title {
  font-size: 11pt !important;
  font-weight: 500;
  margin-bottom: 18px;
  color: #767676;
}

.subsection-title {
  margin: 12px 0 8px;
  text-transform: capitalize;
}

.subsection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ion-button.checkAll {
  font-size: 12px;
  --border-width: 1px;
}
</style>