<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t(content.title) }}</ion-title>

        <template v-if="content.multiple">
          <ion-buttons slot="end">
            <ion-button @click="setOption">{{ $t('Seleccionar') }}</ion-button>
          </ion-buttons>
        </template>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">

      <ion-searchbar
        class="defaultSearchBar"
        :placeholder="$t('Buscar elemento...')"
        v-model="search"
        @ionInput="$emit('search', search)"
        :debounce="500"
      />

      <template v-if="content?.sections?.length">
          <template
            v-for="(section, i) in content.sections"
            :key="`section-${i}`"
          >
              <h5>{{ $t(section.title) }}</h5>

              <ion-list style="border-radius: 15px">
                <ion-item
                  v-for="(item, key) in data[section.key]"
                  :key="`section-${key}`"
                  @click="setOption(item)"
                >
                  <!-- TODO: @Add-on: Imagen por item -->
                  <!-- @click.prevent="$emit('done', material)" -->
                  <!-- <ion-avatar slot="start" v-if="material.imagen">
                    <img :src="material.imagen" @click.stop="this.$emit('done:image', material)"/>
                  </ion-avatar> -->

                  <ion-label style="white-space: normal">
                    <template v-for="(s, i) in structure" :key="`tag-${i}`">
                      <component :is="s.tag" v-bind="s.props">
                        {{
                          +s['key']?.length ?
                            item[s['key']]
                            : '-'
                        }}
                      </component>
                    </template>
                  </ion-label>
                </ion-item>
              </ion-list>
          </template>
      </template>

      <template v-else-if="!content?.sections?.length">
          <ion-list style="border-radius: 15px" lines='full'>
            <ion-item @click="$emit('done', null)">
              <ion-label>
                <h3 style="font-weight: 500">– {{ $t(content.title) }} –</h3>
              </ion-label>
            </ion-item>

            <template v-if="data?.length">
              <ion-item
                v-for="(item, key) in data"
                :key="`item-${key}`"
                @click="!content.multiple && setOption(item)"
              >
                <template v-if="content.multiple">
                  <ion-checkbox slot="start" v-model="item.active" label-placement='end'>
                    <ion-label style="white-space: normal">
                      <template v-for="(s, i) in structure" :key="`tag-${i}`">
                        <component :is="s.tag" v-bind="s.props" :class="['wrap-text']">
                          {{
                            +s['key']?.length ?
                              item[s['key']]
                              : '-'
                          }}
                        </component>
                      </template>
                    </ion-label>
                  </ion-checkbox>
                </template>
                <template v-else>
                  <ion-label style="white-space: normal">
                    <template v-for="(s, i) in structure" :key="`tag-${i}`">
                      <component :is="s.tag" v-bind="s.props" :class="['wrap-text']">
                        {{
                          +s['key']?.length ?
                            item[s['key']]
                            : '-'
                        }}
                      </component>
                    </template>
                  </ion-label>
                </template>

              </ion-item>
            </template>
            <template v-else>
              <ion-item>
                <p>{{ $t(content.empty_title) }}</p>
              </ion-item>
            </template>
          </ion-list>
      </template>

      <template v-if="paginated">
        <ion-infinite-scroll
            threshold="100px"
            id="infinite-scroll"
            @ionInfinite="$emit('load', $event)"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              :loading-text="$t('Cargando más...')"
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
      </template>
    </ion-content>

  </ion-modal>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { structuredContent, contentFinder } from '@/utils/Interfaces/MSetFinderStructure'

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonCheckbox,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
} from '@ionic/vue';


// TODO: Molaria meter tags con contenido custom...
export default defineComponent ({
  name: 'MSetFinder',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    },

    paginated: {
      required: false,
      type: Boolean,
      default: false
    },

    returnWholeObject: {
      required: false,
      type: Boolean,
      default: false
    },

    content: {
      required: true,
      type: Object as PropType<contentFinder>,
      default: () => ({
        title: 'Selecciona una opción',
        empty_title: 'No hay opciones',
        multiple: false,
        sections: []
      })
    },

    structure: {
      required: true,
      type: Array as PropType<structuredContent[]>,
      default: () => ([{
        tag: 'h3',
        key: 'id',
        custom: false,
        // TODO: Propiedades de los tags: class, id, etc.
        props: {}
      }])
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonCheckbox,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
  },

  watch: {
    modelValue(state) {
      if (!state) this.search = '';
    }
  },

  setup() {
    return {
      search: ref(''),
    };
  },

  methods: {
    setOption(item?: { id?: number; [key: string]: any }) {
      const object = this.returnWholeObject ? item : item?.id;
      if (!this.content.multiple) this.$emit('done', object)
      else {
        const selectedOptions:number[] = this.data?.filter((o:any) => o.active).map((o:any) => this.returnWholeObject && o || o.id) || [];
        this.$emit('done', selectedOptions);
      }
    }
  },
});
</script>

<style lang="scss" scoped>
ion-label > p {
  color: rgba(0,0,0, 0.95) !important;
}
.wrap-text{
  white-space: wrap;
}
</style>