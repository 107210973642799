// STORES
import { useOfflineStore } from '@/stores/offline';
import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// SyncController
import { OfflineModule } from '@/utils/OfflineModule/OfflineModule';

// ApiController
import { ApiController } from '@/utils/OfflineModule/ApiController';

// Ionic Components
import { loadingController } from '@ionic/vue'

interface Dependency {
    c: Change;
    i: number
}

interface SyncQueue {
    name: string,
    fetchFn: (data?: any) => any | void,
    dependsOn?: string
}

interface DependencyProp {
    keyInDependent: string,
    keyInParent: string,
}

interface ChangeProp {
    layer: number;
    group: string;
    tempId?: number;
    parentTempId?: number;
    dependsOn?: string[];
    dependencies?: DependencyProp[];
}

interface SyncObject {
    props: ChangeProp; // Para establecer un orden de cambios
    callFunction: string;
    data: object | any;
    // formData?: FormData; // FIXME: TEMPORAL: la idea es que TODO: POST envie siempre con FormData
    headers?: Record<string, string>
}

/** FIXME: Se penso crear un arrar agrupado por capas y prioridades | Pero luego se llego a la conclusión de simplificarlo - quedando la implementación actual 
 * Update #1 - Volvemos a capas/prioridades:
 *  Necesito el id (nuevo parte/incidencia) para luego poder asociar horas/maquinas/etc a ese nuevo parte...
*/

/**
 * Represents a change in the synchronization process.
 *
 * - `id`: Unique identifier for the change, represented as an epoch timestamp.
 * - `syncObject`: The object that contains Axios params needed for a request to API.
 * - `layer`: Indicates the layer where the change is applied.
 * - `group`: Group to which this change belongs, useful for categorizing changes.
 * - `priority`: Priority level of the change.
 * - `tempId`: Temporary identifier for the change, used during the sync process.
 * - `parentTempId`: Temporary identifier of the parent change, if applicable.
 * - `dependsOn`: List of changes that this change depends on (by their group).
 * - `dependencies`: Detailed dependency properties for this change, represented as `DependencyProp` objects.
 *
 * A little of context:
 *  User enters in offline mode, creates a wo and adds an image to it.
 *  This is how sync has to occur
 *      1. We create the WO
 *      2. Then with the workorder created, we fetch the id_parte of it and
 *          associate it to the image object
 *      3. Finally we sync the image object with recent created wo
 * REVIEW: TODO: Needs more specification
 * @example
 * const change: Change = {
 *   id: Date.now(),
 *   syncObject: change,
 *   layer: 2,
 *   group: "images",
 *   priority: 1,
 *   tempId: 1733395200,
 *   dependsOn: ["partes"],
 *   dependencies: [{ keyInDependent: "id_parte", keyInParent: "id" }]
 * };
 */
interface Change {
    id: EpochTimeStamp;
    syncObject: SyncObject;
    layer: number;
    group: string;
    priority: number; // FIXME: Forma para determinar la prioridad
    tempId?: number;
    parentTempId?: number;
    dependsOn?: string[];
    dependencies?: DependencyProp[];
}

export class SyncController {

    private store: Change[] = [];
    // private store: Record<string, Change[]> = {};

    private _change!: Change;
    private apiController: ApiController;

    public offline: any = useOfflineStore();
    public gmao: any = useGmaoStore();
    public workorders: any = useWorkOrdersStore();
    

    private cancelToken: any = null;

    // Add download metrics
    private downloadMetrics = {
        totalTables: 0,
        completedTables: 0,
        failedTables: [] as string[],
        errors: [] as { table: string, error: Error }[]
    };

    constructor() {
        // Cargamos las reglas de sincronizacion
        this.store = [];
        SyncController.loadRules();

        this.apiController = new ApiController();

    }

    /**
     * Agrega un nuevo cambio al almacenamiento de sincronización. 
     * La función gestiona duplicados, normaliza el objeto recibido 
     * y lo almacena en la lista interna. Finalmente, ordena el 
     * almacenamiento para mantener el orden correcto.

     * @param change - Objeto de cambio que se agregará, debe seguir la estructura definida por `SyncObject`.
     * @returns Promise<void> - Indica que la función es asíncrona y no devuelve ningún valor.
    */
    public async addChange(change: SyncObject): Promise<void> {
        // NOTE: Comprobamos que haya un registro igual en cuanto al ID
        this.handleDuplicates(change);

        // Normalize received change object
        this._change = await SyncController.normalizeChange(change);
        // BUG: Si añadimos un registro creado desde el offline y luego lo borramos, tenemos que
        //  de alguna manera recuperar el id de creación para realizar bien el borrado
        // O ni siquiera sincronizarlo NOTE: (creo que será más facil)

        // Save the changes
        this.store.push(this._change);

        console.log('Store: ', this.store);
        // Sort Changes Store
        this.sortStore();

        // REVIEW: Creamos un metodo para comprobar que está bien ordenado?
    }

    /**
     * Sincroniza los cambios almacenados en el sistema con la API remota.
     * La función muestra un mensaje de carga durante el proceso, sincroniza cada cambio de manera secuencial
     * y luego descarga la información actualizada de la producción. Maneja posibles errores
     * durante el proceso de sincronización.
     *
     * @returns Promise<void> - Indica que la función es asíncrona y no devuelve ningún valor.
    */
    public async syncChanges(): Promise<void> {
        try {
            this.offline.loading = await loadingController.create({
                message: 'Sincronizando datos',
            });
            await this.offline.loading.present();

            while (this.store.length > 0) {
                // XXX: REVIEW: El método shift borra el elemento – si no se sincronizan cambios se perderan...
                const change: Change | undefined = this.store.shift();
                await this.syncAPIChange(change);
            }
            setTimeout(async () => {
                await this.offline.loading.dismiss();
            }, 2000);

            /** Una vez terminamos, sincronizamos los últimos cambios */
            await this.downloadProduction();
        } catch (error) {
            console.error(`Ha ocurrido un error al sincronizar`, error);
        } finally {
            await this.offline.loading.dismiss();

            /** HACK: Apaño para fixear el contador que se queda pillado a veces.  */
            if (this.offline.counterQueue > 0) this.offline.counterQueue = 0;
        }
        
    }

    /**
     * NOTE: Checks for duplicates in store. Following cases may apply:
     *      - When editing (parte_horas)
     */
    private handleDuplicates(change: SyncObject): void {
        const findIndex = this.store?.findIndex((i: Change) => i.tempId == change.props.tempId);
        console.log('Handle duplicades:: ', findIndex, change);


        // NOTE: Borramos el elemento previo
        if (findIndex >= 0) this.removeChange(findIndex);
    }

    /**
     * Elimina un cambio del almacenamiento local (`store`) en un índice específico. 
     * Si el índice es negativo, la operación no se realiza.
     *
     * @param storeIndex - Índice del elemento en el almacenamiento que se desea eliminar.
     *                     Debe ser un número no negativo.
    */
    private removeChange(storeIndex: number): void {
        if (storeIndex < 0) return;

        this.store.splice(storeIndex, 1);
    }

    /**
     * Ordena el almacenamiento local (`store`) en función del valor de la propiedad `layer` 
     * de los elementos. Los elementos se ordenan en orden ascendente según el valor de `layer`.
    */
    private sortStore(): void {
        this.store.sort((a, b) => a.layer - b.layer);
    }

    /**
     * Sincroniza un cambio específico con la API. La función envía el cambio como una petición POST,
     * procesando la respuesta y manejando errores en caso de fallos en la sincronización. 
     * Si el cambio no está definido, la operación no se realiza.
     *
     * @param change - Objeto de tipo `Change` o `undefined`. Contiene los datos necesarios para realizar
     *                 la sincronización con la API.
     * @returns Promise<void> - Indica que la función es asíncrona y no devuelve ningún valor.
    */
    private async syncAPIChange(change: Change | undefined): Promise<void> {
        if (change) {

            const payload = change.syncObject.data instanceof FormData ? change.syncObject.data : {data: {...change.syncObject.data}};
            const headers = change.syncObject.headers || {'Content-Type': 'application/x-www-form-urlencoded'};

            await axios.post(`/v2/users/actions.php?call=${change.syncObject.callFunction}&token=${this.gmao.user.token}&v2=1`,
            payload,
            headers)
            .then(async ({ data }) => {
                // Procesamos la respuesta con los cambios restantes.
                let dataKey = '';
                for (const [key, value] of Object.entries(data)) {
                    if (Array.isArray(value) || typeof value === 'object') {
                        dataKey = key;
                    }
                }
                await this.processSyncResponse(change, data[dataKey]);

                await this.processThenCallback(change.syncObject.callFunction, data);

                this.offline.counterQueue--;
            })
            .catch((error: any) => {
                // TODO: ErrorController
                console.error(error);
                /** TODO: Recuperación de datos que no se han sincronizado
                 * Hacer una lista con objetos fallidos para luego mostrar al usuario o avisar de que no se han sincronizados
                 */
            })
            .finally(() => {
                // Salida por defecto para evitar cortes de syncronización
            });
        }
    }

    /**
     * Normaliza un objeto de tipo `SyncObject` para convertirlo en un objeto `Change`.
     * Asigna valores predeterminados a propiedades específicas y agrega una ID única basada
     * en la fecha actual.
     *
     * @param change - Objeto de tipo `SyncObject` que se transformará en un objeto `Change`.
     * @returns Promise<Change> - Devuelve un objeto normalizado de tipo `Change`.
    */
    private static async normalizeChange(change: SyncObject): Promise<Change> {
        const result: Change =
        {
            id: Date.now(),
            syncObject: change,
            priority: 1, // FIXME: Forma para determinar la prioridad
            layer: change.props.layer,
            group: change.props.group,
            tempId: change.props.tempId || 0, // REVIEW: Definir su uso. Ahora sirve para dependencias entre objetos
            parentTempId: change.props.parentTempId || 0,
            dependsOn: change.props.dependsOn || [],
            dependencies: change.props.dependencies || [],
        };

        return result;
    }

    /**
     * Procesa la respuesta de sincronización para un cambio específico. 
     * Actualiza las dependencias de otros elementos en el almacenamiento (`store`) que dependen
     * del cambio sincronizado, si existen.
     *
     * @param change - Objeto de tipo `Change` que representa el cambio procesado.
     * @param response - Respuesta de la sincronización, utilizada para actualizar las dependencias.
     *                   Puede ser de cualquier tipo, por lo que se define como `any`.
     * @returns Promise<void> - Indica que la función es asíncrona y no devuelve ningún valor.
    */
    private async processSyncResponse(change: Change, response: any): Promise<void> {
        /**
         * NOTE: Si la clave apunta a un par que no tiene valor o no existe - no hay nada que actualizar.
        */
        if (response === undefined) return;

        /** Buscamos dependencias entre el cambio que estamos procesando y los que quedan en la store */
        const areThereDependencies = this.store.reduce((acc: Dependency[], c: Change, i: number) => {
            if (c.dependsOn?.includes(change.group) && change.tempId === c.parentTempId) { acc.push({c,i}); }
            return acc;
        }, []);

        /** REVIEW:
         * Caso a observar:
         *  1. Inicio tiempos en online
         *  2. Se me guarda el registro en BD prod y local
         *  3. Paro tiempos en offline
         *  4. BUG: Al sincronizar
         *      - Se comprueba que no hayan dependencias. 
         *          El problema es que no las va a haber porque no está en la cola, ya que se ha hecho en online.
         *          Solution: intentar contemplar este caso.
         * 
         */

        if (areThereDependencies.length)
        {
            areThereDependencies.forEach(async (dependency) => {
                const key = dependency?.i;
                let dependencyChange: Change = this.store[key];

                if (dependencyChange && dependencyChange?.id) {
                    // CP: Aqui se empieza a perder perspectiva CP: \\
                    dependencyChange = await this.updateDependencies(dependencyChange, response)
                }
            });
        }
    }

    /**
     * Actualiza las dependencias de un cambio específico (`dependencyChange`) basándose en los valores 
     * de la respuesta proporcionada (`response`). Identifica las estructuras de datos del cambio y la 
     * respuesta para realizar las actualizaciones correspondientes en los datos dependientes.
     *
     * @param dependencyChange - Objeto de tipo `Change` que contiene las dependencias a actualizar.
     * @param response - Respuesta de la sincronización que se utilizará para actualizar las dependencias.
     * @returns Promise<Change> - Devuelve el cambio actualizado.
    */
    private async updateDependencies(dependencyChange: Change, response: any): Promise<Change> {
        const { data } = dependencyChange.syncObject;

        const typeDependant: number = SyncController.identifyDataStructure(data); // BUG: POSIBLE FUTURO BUG \\
        const typeResponse: number = SyncController.identifyDataStructure(response); // BUG: POSIBLE FUTURO BUG \\

        const dependencies: DependencyProp[] = dependencyChange.dependencies || [];

        /** XXX: ADDED TO SPECIFY CERTAIN CASES
         * 
         * Not as usual calls, but some of them have a key in the middle of data and the
         *  object. E.x, when tracking days hours we have a "jornadas" key in the middle
         * 
         * This may produce BUG: so when the time comes to fix this, remove the line below and
         *  its dependencies.
         */
        const specialControls = ['jornada_horas'];

        /** NOTE: Debug */
        // console.log(
        //     structuredClone(data), 
        //     structuredClone(typeDependant), 
        //     structuredClone(typeResponse),
        //     structuredClone(dependencies));

        dependencies?.length && dependencies.forEach((d) => {
            const {keyInDependent, keyInParent} = d;

            const parentValue = typeResponse === 1 ?
                response.at(0)[keyInParent] : (typeResponse === 2 ? response[keyInParent] : null);

            if (parentValue) {
                switch (typeDependant) {
                    case 0: // FormData
                        data?.set(keyInDependent, parentValue);
                        break;
                    case 1: // Array
                        // BUG: POSIBLE FUTURO BUG - TIPO del Map \\
                        data.map((d: Record<string | number, any>) => {
                            d[keyInDependent] = parentValue;
                            return d;
                        })
                        break;
                    case 2: // Object
                        if (specialControls.includes(dependencyChange.group)) data['jornadas'][0][keyInDependent] = parentValue;
                        else {
                            data[keyInDependent] = parentValue;
                        }
                        break;

                    default:
                        break;
                }
            }
        })

        return dependencyChange;
    }

    /**
     * Identifica la estructura de datos proporcionada (`data`) y devuelve un código numérico
     * correspondiente al tipo de estructura:
     * - 0: FormData no vacío.
     * - 1: Array.
     * - 2: Object no vacío.
     * - -1: Tipo de datos no reconocido o vacío.
     *
     * @param data - La estructura de datos a evaluar. Puede ser `FormData`, un `Array` de objetos o un `Object`.
     * @returns number - Código numérico que representa el tipo de datos identificado.
    */
    private static identifyDataStructure(data: FormData | Array<object> | object): number {
        if (data instanceof FormData && !data?.entries()?.next().done) {
            // FORMDATA === 0
            return 0;
        } else if (Array.isArray(data)) {
            // ARRAY === 1
            return 1;
        } else if (Object.keys(data)?.length) {
            // OBJECT === 2
            return 2;
        }

        return -1;
    }

    /**
     * Procesa una llamada específica y ejecuta las acciones correspondientes en función del nombre
     * de la llamada (`callName`). Actualmente, maneja el caso de `'setWorkorderTimes'` para actualizar 
     * el registro de tiempos de las órdenes de trabajo (`workorders.timeRegister`).
     *
     * @param callName - Nombre de la función de llamada que define la acción a realizar.
     * @param data - Datos asociados a la llamada, que se utilizan para procesar las actualizaciones.
     * @returns Promise<void> - Indica que la función es asíncrona y no devuelve ningún valor.
    */
    private async processThenCallback(callName: string, data: any): Promise<void> {
        if (!callName.length) return;

        switch (callName) {
            case 'setWorkorderTimes':
                /** NOTE: Migrated from Workorder[8008 - 8023] */
                if (!data?.times?.at(0)?.fin) {
                    const tiempo = data?.times?.at(0);
                    this.workorders.timeRegister = {
                        id: tiempo?.id,
                        from: moment(`${(tiempo?.desde_formatted || tiempo.inicio)}`).format(),
                        workorder: tiempo.id_parte,
                        hour_type: tiempo.id_tipo_tiempo,
                        longitud: tiempo.longitud,
                        latitud: tiempo.latitud,
                        created_by: tiempo.created_by,
                        current_timer: tiempo?.id,
                    };
                } else {
                    this.workorders.timeRegister = {};
                }

                break;
            default:
                break;
        }
    }

    // XXX: Igual prescindimos de esta funcion.
    private static loadRules() {
        /** ADD SYNC RULES
         * La idea es agrupar y dar prioridad a algunas actualizaciones antes que a otras.

         * TODO:
         *  Tenemos diferentes capas:
         *      - Capa 1 (nuevo parte/incidencia)
         *      - Capa 2 (nueva maquina/hora del parte/incidencia)
         *          * Aqui necesitamos el nuevo id de la capa 1.
         *      - Capa 3 (nuevo material con maquina asociada)
         *          * Aqui necesitamos el nuevo id de la capa 1 y de la capa 2
         */

        // SyncController.rules = {
        //     'setParte': {
        //         priority: 1,
        //         group: 'partes',
        //         dependencies: []
        //     },
        //     'setIncidencia': {
        //         priority: 1,
        //         group: 'incidencias',
        //         dependencies: []
        //     },
        //     'default': {
        //         priority: 999,
        //         group: 'default',
        //         dependencies: ['']
        //     }
        // }
    }

    /**
     * Vacía el almacenamiento local (`store`), eliminando todos los elementos que contiene.
     * Deja el almacenamiento como un array vacío.
    */
    public emptyStore(): void {
        this.store = [];
    }

    /**
     * Descarga y sincroniza los datos necesarios para la producción en modo offline.
     * Utiliza un módulo offline (`OfflineModule`) para construir la base de datos SQLite
     * y sincronizar datos desde diversas fuentes a las tablas correspondientes.
     *
     * @returns Promise<void> - Indica que la función es asíncrona y no devuelve ningún valor.
    */
    public async downloadProduction(): Promise<void> {
        this.offline.showLoader = true;
        
        // Reset metrics for new download
        this.downloadMetrics = {
            totalTables: 0,
            completedTables: 0,
            failedTables: [],
            errors: []
        };

        // Traemos la instancia del modulo offline - REVIEW:
        
        const offlineModule = OfflineModule.getInstance();
        await offlineModule.initDBConnection();

        try {
            const userObject = this.gmao.user;
            const schemas = await this.apiController.getSchemasDB();

            // NOTE: Build Database
            await offlineModule?.buildSQLiteDB(schemas);

            const dataToSync: Array<SyncQueue> = [
                { name: 'usuarios', fetchFn: async () => Promise.resolve(userObject), dependsOn: undefined },
                { name: 'direcciones', fetchFn: async () => this.apiController.getDirecciones(), dependsOn: undefined },
                { name: 'partes', fetchFn: async () => this.apiController.getPartes(), dependsOn: undefined },
                { name: 'usuarios', fetchFn: async () => this.apiController.getTecnicos(), dependsOn: undefined },
                { name: 'jornada_horas', fetchFn: async () => this.apiController.getJornadasTecnico(), dependsOn: undefined },
                { name: 'jornada_horas', fetchFn: async () => this.apiController.getJornadas(), dependsOn: undefined },
                { name: 'vehiculos', fetchFn: async () => this.apiController.getVehicles(), dependsOn: undefined },
                { name: 'tipos_tiempo', fetchFn: async () => this.apiController.getHourTypes(), dependsOn: undefined },
                { name: 'parte_tipos', fetchFn: async () => this.apiController.getWoTypes(), dependsOn: undefined },
                { name: 'proyecto_parte_tipos', fetchFn: async () => this.apiController.getProyectoParteTipos(), dependsOn: undefined },
                { name: 'textos_predefinidos', fetchFn: async () => this.apiController.getSolutionTexts(), dependsOn: undefined },
                { name: 'maquinas',
                    fetchFn: async (result?: any) => this.apiController.getMaquinas(result),
                    dependsOn: 'direcciones'
                },
                { 
                    name: 'modelos_parte_campos_respuestas',
                    fetchFn: async (result?: any) => this.apiController.getWorkOrderAssetChecklist(result),
                    dependsOn: 'partes'
                },
                { name: 'gamas', fetchFn: async () => this.apiController.getGamas(), dependsOn: undefined },
                { name: 'faq_preguntas', fetchFn: async () => this.apiController.getQuestions(), dependsOn: undefined },
                { name: 'faq_respuestas', fetchFn: async () => this.apiController.getReplies(), dependsOn: undefined },
                { name: 'maquina_modelos', fetchFn: async () => this.apiController.getModelos(), dependsOn: undefined },
                { name: 'repuestos_en_maquina', fetchFn: async () => this.apiController.getRepuestosMaquinaSQL(), dependsOn: undefined },
                { name: 'usuarios_vacaciones', fetchFn: async () => this.apiController.getIsWorkingDay(), dependsOn: undefined },
            ];

            /** NOTE: PENDING: Añadir en proximas versiones
             * { name: 'documentos', fetchFn: async () => this.apiController.getDocumentosSQL(), dependsOn: undefined },
             * { name: 'lotes', fetchFn: async () => this.apiController.getLotesMaterial(), dependsOn: undefined },
             * { name: 'almacenes', fetchFn: async () => this.apiController.getAlmacenes(), dependsOn: undefined },
             * { name: 'articulos', fetchFn: async () => this.apiController.getMaterials(), dependsOn: undefined },
             * { name: 'almacen_articulos', fetchFn: async () => this.apiController.getAlmacenArticulos(), dependsOn: undefined },
             * { name: 'lotes_almacenes', fetchFn: async () => this.apiController.getLotesAlmacenesSQL(), dependsOn: undefined }
             */

            // Mapa para almacenar los resultados de cada llamada
            
            // Set total tables to track
            this.downloadMetrics.totalTables = dataToSync.length;

            const resultsMap: Record<string, any> = {};

            for (const { name, fetchFn, dependsOn } of dataToSync) {
                try {
                    let records;

                    if (dependsOn) {
                        const dependencyResult = resultsMap[dependsOn];
                        if (!dependencyResult) throw new Error(`Dependency ${dependsOn} has not been resolved yet.`);

                        records = await fetchFn.bind(this)(dependencyResult);
                        
                    } else {
                        records = await fetchFn.bind(this)();
                    }

                    // Almacena los resultados en el mapa para futuros usos
                    resultsMap[name] = records;

                    try {
                        /** NOTE: Guarda los datos en la base de datos local
                         * HACK: Hemos añadido un try/catch para que en caso de que
                         *  alguna inserción falle, continuar con la siguiente.
                         */
                        await offlineModule?.setInitialData(name, records);
                        this.downloadMetrics.completedTables++;
                    } catch (error) {
                        this.downloadMetrics.failedTables.push(name);
                        this.downloadMetrics.errors.push({
                            table: name,
                            error: error as Error
                        });
                        console.error(`Error syncing table ${name}:`, error);
                        continue;
                    }
                } catch (error) {
                    this.downloadMetrics.failedTables.push(name);
                    this.downloadMetrics.errors.push({
                        table: name,
                        error: error as Error
                    });
                    console.error(`Error processing table ${name}:`, error);
                    continue;
                }
            }

            this.offline.showLoader = false;
        } catch (error) {
            this.offline.showLoader = false;
            console.error(error);
            // TODO: ErrorController
            // app?.$Sentry.captureException(error);
            throw error;
        } finally {
            console.log('Download metrics:', this.downloadMetrics);
            this.offline.showLoader = false;
        }
    }

    // Add getter for download metrics
    public getDownloadMetrics() {
        return { ...this.downloadMetrics };
    }
}
