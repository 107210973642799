<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar operario') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar :placeholder="$t('Buscar técnicos...')" v-model="tecnicoSearch" />

        <ion-list style="border-radius: 15px">
          <ion-item v-if="!unassigned" @click="$emit('done', null)">
            <ion-label>
              <h3 style="font-weight: 500">
                {{ $t('Desasignarme el parte') }}
              </h3>
            </ion-label>
          </ion-item>
          <ion-item
            v-for="(operario, key) in computedSelectableTecnicos"
            :key="`modal-operario-${key}`"
            @click="$emit('done', operario)"
          >
            <ion-label>
              <h3>{{ operario?.nombre_completo }}</h3>
            </ion-label>
          </ion-item>
        </ion-list>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonSearchbar,
} from '@ionic/vue';

import { ref } from 'vue';

export default {
  name: 'MSetOperario',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    },

    unassigned: {
      required: true,
      type: Boolean,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonSearchbar,
  },

  computed: {
    computedSelectableTecnicos() {
      return this.data?.filter((t) =>
        t?.nombre_completo.toLowerCase().includes(this.tecnicoSearch?.toLowerCase() || '')
      );
    },
  },

  setup() {

    return {
      tecnicoSearch: ref(null),
    };
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}
</style>