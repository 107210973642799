<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('close')">
              {{ $t('Cancelar') }}
          </ion-button>
          </ion-buttons>
        <ion-title>{{ $t('Abrir nuevo parte') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-item :disabled="this.offline.status" v-if="+gmao.modulos.modulo_proyectos" @click="modalProject">
        <ion-label>{{ $t('Proyecto') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="`${workorderData.proyecto?.cod_proyecto || ''} ${workorderData.proyecto?.titulo || ''}`"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item v-if="teams?.length" @click="chooseTeam">
        <ion-label>{{ $t('Equipo') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="workorderData.team?.nombre"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item v-if="!+gmao.comportamientos?.gmao_interno" @click="chooseAddress">
        <ion-label>{{ $t('Dirección') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="workorderData.address?.nombre"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item @click="setWoType = true" v-if="woTypes?.length">
        <ion-label>{{ $t('Subtipo') }}</ion-label>
        <ion-input
          class="ion-text-right"
          :value="workorderData.type?.nombre"
          readonly
          clear-input
        ></ion-input>
      </ion-item>

      <ion-item style="--padding-bottom:-30px;">
        <ion-textarea
          rows="9"
          v-model="workorderData.problema"
          :label="$t('Descripcion')"
          label-placement="floating"
        ></ion-textarea>
      </ion-item>

      <ion-button :disabled="disableCreate" expand="full" @click="createWorkorder(workorderData)">
        {{ $t('Crear Ot') }}
      </ion-button>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setAddress"
    @didDismiss="setAddress = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setAddress = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-direccion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        v-if="searchAddresses"
        :placeholder="$t('buscar-direccion')"
        v-model="addressSearchAjax"
        @ionInput="getSearchAddresses(true)"
        :debounce="1000"
      />

      <ion-searchbar
        v-else
        :placeholder="$t('buscar-direccion')"
        v-model="addressSearch"
      />

      <ion-list>
        <ion-item
          @click="selectDireccion(null)"
        >
          <ion-label>
            <h3>{{ $t('Sin direccion') }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          v-for="address in computedAddresses"
          :key="`address-${address.id}`"
          v-model="newWorkorder.address"
          @click="selectDireccion(address)"
        >
          <ion-label>
            <h3>{{ address.nombre + ' - ' + address?.cliente?.nombre }}</h3>
            <p>{{ address.direccion }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll-activos"
        @ionInfinite="onLoadDirecciones($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más direcciones...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setTeam"
    @didDismiss="setTeam = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setTeam = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-equipo') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('buscar-equipo')"
        v-model="teamSearch"
      />

      <ion-list>
        <ion-item
          @click="workorderData.team = null; setTeam = false"
        >
          <ion-label>
            <h3>{{ $t('Sin equipo') }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          v-for="team in computedTeams"
          :key="`team-${team.id}`"
          v-model="newWorkorder.team"
          @click="selectEquipo(team)"
        >
          <ion-label>
            <h3>{{ team.nombre }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setWoType"
    @didDismiss="setWoType = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setWoType = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-subtipo') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('buscar-subtipo')"
        v-model="typeSearch"
      />

      <ion-list>
        <ion-item
          @click="workorderData.type = null; setWoType = false"
        >
          <ion-label>
            <h3>{{ $t('Sin subtipo') }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          v-for="type in computedWoSubtypes"
          :key="`type-${type.id}`"
          v-model="newWorkorder.type"
          @click="selectType(type)"
        >
          <ion-label>
            <h3>{{ type.nombre }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setProject"
    @didDismiss="setProject = false"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setProject = false">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('Seleccionar proyecto') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-searchbar
        :placeholder="$t('Buscar proyecto...')"
        v-model="projectSearch"
        @ionInput="applyFilters(true)"
        :debounce="1000"
      />

      <ion-list>
        <ion-item
          @click="workorderData.proyecto = null; setProject = false; selectProyecto(0)"
        >
          <ion-label>
            <h3>{{ $t('Sin proyecto') }}</h3>
          </ion-label>
        </ion-item>
        <ion-item
          v-for="proyecto in proyectos.flat()"
          :key="`proyecto-${proyecto.id}`"
          v-model="newWorkorder.proyecto"
          @click="selectProyecto(proyecto)"
        >
          <ion-label>
            <h3>{{ proyecto?.cod_proyecto + ' - ' + proyecto.titulo }}</h3>
            <!-- <p>{{ proyecto.direccion }}</p> -->
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll-activos"
        @ionInfinite="onLoad($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más proyectos...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonSearchbar,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonInput,
  IonItem,
  IonTextarea,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  loadingController,
} from '@ionic/vue';
import { informationCircle } from 'ionicons/icons';

import { ref, getCurrentInstance } from 'vue';
import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';

// OfflineModule
import { OfflineModule } from '@/utils/OfflineModule/OfflineModule';

// OfflineActionsController
import { OfflineActionsController } from '@/utils/OfflineModule/OfflineActionsController';

import { useOfflineStore } from '@/stores/offline';

export default {
  name: 'NewWorkorder',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    incidence: {
      type: Object,
    },

    addresses: {
      type: Object,
    },

    customAddress: {
      type: Object,
    },

    customFacility: {
      type: Object,
    },

    anomaliesAssets: {
      type: Array,
    },

    searchAddresses: {
      type: Boolean,
    }
  },

  emits: [
    'done',
  ],

  components: {
    IonModal,
    IonButton,
    IonSearchbar,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonInput,
    IonItem,
    IonTextarea,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },

  computed: {
    computedAddresses() {
      return (this.searchedAddresses.flat())?.filter((a) =>
        Object.values({
            direccion: a?.direccion,
            nombre: a?.nombre,
            cp: a?.cp,
            cliente: a.cliente?.nombre,
            })
          .join(';')
          ?.toLowerCase()
          .includes((this.addressSearch || '').toLowerCase())
      );
    },

    computedTeams() {
      return this.teams?.filter((a) =>
        (a?.nombre || '')?.toLowerCase()
          .includes((this.teamSearch || '').toLowerCase())
      );
    },

    computedWoSubtypes() {
      return this.woTypes?.filter((a) =>
        (a?.nombre || '')?.toLowerCase()
          .includes((this.typeSearch || '').toLowerCase())
      );
    }
  },

  setup() {
    const gmao = useGmaoStore();
    const wo = useWorkOrdersStore();

    const offline = useOfflineStore();
    const offlineActionsController = new OfflineActionsController();

    const app = getCurrentInstance();

    return {
      offline,
      app,
      gmao,
      wo,
      informationCircle,
      setAddress: ref(false),
      setTeam: ref(false),
      setProject: ref(false),
      disableCreate: ref(false),
      addressSearch: ref(null),
      addressSearchAjax: ref(null),
      teamSearch: ref(null),
      projectSearch: ref(null),
      newWorkorder: ref({}),
      workorderData: ref({}),
      proyectos: ref([]),
      searchedAddresses: ref([]),
      typeSearch: ref(''),
      woTypes: ref([]),
      setWoType: ref(false),
      teams: ref([]),

      current_page: ref(1),
      last_page: ref(null),
      loading: ref(false),
      total: ref(null),

      // ==> Offline related
        offlineModule: ref(null),
        offlineActionsController,
        axiosPayload: ref({}),
      // <== Offline related
    };
  },

  created(){},

  watch: {
    'modelValue': {
      handler(val) {
        if (val) {
          this.offlineModule = OfflineModule.getInstance();
          this.getDirecciones();
          if (
            this.customAddress?.id ||
            ((this.addresses?.length == 1 || this.searchedAddresses?.flat()?.length == 1)
            && +this.gmao.user.comportamientos?.direccion_preestablecida_autosignacion)
          ) {
            this.workorderData.address = this.customAddress || this.addresses[0] || this.searchedAddresses.flat()[0];
          }

          this.getWoTypes();
        }
      },
      deep: true
    },

    'proyectos': {
      handler(val) {
        if (val?.length == 1) {
          this.workorderData.proyecto = val[0];
        }
      }
    },

    'addresses': {
      handler(val) {
        if (val.length == 1) this.workorderData.address = val[0];
      }
    },

    'teams': {
      handler(val) {
        if (val.length == 1) this.workorderData.team = val[0];
      }
    },

    setAddress(v) {
      if (!+this.gmao.comportamientos.ver_direcciones_en_proyecto) {
        if (v) this.applyFiltersDirecciones(true);
      }
    }
  },

  methods: {
    selectProyecto(proyecto) {
      this.workorderData.proyecto = proyecto;
      this.setProject = false;
      this.getWoTypes();
      this.getTeams(this.workorderData?.proyecto?.id);

      this.workorderData.type = null;
      if(+this.gmao.comportamientos.ver_direcciones_en_proyecto && this.workorderData.proyecto != 0) {
        this.applyFiltersDirecciones(true, this.workorderData.proyecto);
      } else this.$emit('proyecto', proyecto.id);
    },

    getShowCotaCeroWarning(address) {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getCotaCeroWarning',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            address: address?.id || '',
            v2: 1,
          },
        })
        .then(({ data }) => {
          if (typeof data == 'boolean' && (data == true || data == 'true')) {
            this.$openToastObject(
              this.$t('Aviso!'),
              this.$tc('cota_cero_riesgo_coste_msg', {cliente: address?.cliente?.nombre || ''}),
              'dark',
              'bottom',
            );
          }
        });
    },

    getTeams(proyecto) {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getEquipos',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            proyecto: proyecto || '',
            v2: 1,
            page: -1
          },
        })
        .then(({ data }) => {
          this.teams = data;
        });
    },

    getWoTypes() {
      console.log('A');
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getWoTypes',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            proyecto: this.workorderData?.proyecto?.id || '',
            v2: 1,
          },
        }).then(({ data }) => {
          return this.woTypes = data;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
              try {
                const tiposPromise = new Promise((resolve) => {
                  const tiposParte = this.offlineModule?.db.query(`
                    SELECT *
                    FROM parte_tipos
                    ${this.workorderData?.proyecto?.id ? `
                    WHERE id IN (
                      SELECT id_parte_tipo
                      FROM proyecto_parte_tipos
                      WHERE id_proyecto=${this.workorderData?.proyecto?.id}
                    );
                  )`: ''}`);
                  resolve(tiposParte);
                });

                tiposPromise.then((value) => {
                  const valores = value.values;
                  if (!valores?.length) {
                    const todosTiposPromise = new Promise((resolve) => {
                      const tiposParte = this.offlineModule?.db.query(`SELECT * FROM parte_tipos;`);
                      resolve(tiposParte);
                    });
                    todosTiposPromise.then((value) => {
                      setTimeout(() => {
                        this.woTypes = value.values;
                        // sqlite.closeConnection('gmaoTecnicos');
                      }, 500);
                    });
                  } else  {
                    setTimeout(() => {
                      this.woTypes = valores;
                      // sqlite.closeConnection('gmaoTecnicos');
                    }, 500);
                  }
                });
              } catch (err) {
                // ErrorController
                // this.$Sentry.captureException(err);
              }
            }
        })
    },

    modalProject() {
      this.setProject = true;
      this.applyFilters(true);
    },

    getSearchAddresses(reset = true) {
      if(reset) this.searchedAddresses = [];
      // this.getDirecciones(this.addressSearchAjax);
      this.applyFiltersDirecciones(true);
    },

    onLoadDirecciones(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersDirecciones(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    applyFiltersDirecciones(reset = true,proyecto = null) {
      if (reset) {
        this.searchedAddresses = [];
        this.current_page = 1;
      }
      return this.getDirecciones(this.addressSearchAjax,proyecto);
    },

    async getDirecciones(search = null,proyecto = null) {
      // if (this.addresses?.flat()?.length) {
      //   this.searchedAddresses = this.addresses;
      //   return;
      // }

      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getDireccionesModal',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            page: this.current_page,
            v2: 1,
            proyecto: proyecto ? proyecto.id : null,
            search
          },
        })
        .then(({ data }) => {
          // this.searchedAddresses = [];
          this.searchedAddresses.push(data.data);
          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
          if ((this.addresses?.length == 1 || this.searchedAddresses?.flat()?.length == 1) && +this.gmao.user.comportamientos?.direccion_preestablecida_autosignacion) {
            this.workorderData.address = this.addresses?.[0] || this.searchedAddresses?.flat()[0];
          } else {
            this.workorderData.address = null;
          }
        }).catch(async (e) => {
          // TODO: REVISAR*
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const query = `SELECT * FROM direcciones`;
              const getDireccionesPromise = new Promise((resolve) => {
                resolve(this.offlineActionsController.SQLiteQuery('getDirecciones_workorders', 'direcciones', query, e.config, this.offlineModule?.db));
              });
              getDireccionesPromise.then(() => {
                this.searchedAddresses = [];
                setTimeout(() => {
                  this.searchedAddresses = this.offline.addresses;
                }, 5);
              });
            } catch (err) {
              // Error Controller
              // this.$Sentry.captureException(err);
            }
          }
        });
    },

    selectDireccion(address) {
      // NOTE: Migrado del codigo de arriba
      if (!address) {
        this.workorderData.address = null;
        this.setAddress = false
        return;
      }

      // NOTE: Comprobamos si se ha excedido el aviso de cota cero
      // NOTE: XXX: TODO: Solo Online
      if (!this.offline.status && +this.gmao.modulos.modulo_cota_cero) {
        this.getShowCotaCeroWarning(address);
      }
    
      this.workorderData.address = address;
      this.setAddress = false;
      if (!this.workorderData.proyecto) {

        if (+this.gmao.modulos.modulo_proyectos) {
          this.setProject = true;
          this.workorderData.proyecto = null;
        }

        this.applyFilters(true, true);

      } else this.applyFilters(true);
    },

    selectEquipo(team) {
      this.workorderData.team = team;
      this.setTeam = false;
    },

    selectType(type) {
      this.workorderData.type = type;
      this.setWoType = false;
    },

    chooseAddress() {
      if (!((this.addresses.flat()?.length == 1 || this.searchedAddresses?.flat()?.length == 1) && +this.gmao.user.comportamientos?.direccion_preestablecida_autosignacion)) {
        this.setAddress = true;
      }
    },

    chooseTeam() {
      if (this.teams.length != 1) {
        this.setTeam = true;
      }
    },

    onLoad(event, statusCall = false) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false, statusCall).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFilters(reset = true, statusCall = false) {
      if (reset) {
        this.proyectos = [];
        this.current_page = 1;
      }
      return this.getProyectos(statusCall);
    },

    getProyectos(withProject = false) {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getProyectos',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            direccion: withProject && this.workorderData?.address?.id || null,
            cliente: this.workorderData?.address?.cliente?.id || null,
            search: this.projectSearch,
            page: this.current_page,
            v2: 1,
          },
        }).then(({ data }) => {
          if (data?.data?.length) {
            this.proyectos.push(data.data);

            this.current_page = data.current_page;
            this.last_page = data.last_page;
            this.total = data.total;
          }
        }).catch(() => {
          // FIXME: REVISAR* TODO: Offline (AÑADIR TABLAS DE PROYECTOS...)
        });
    },

    async createWorkorder(woData) {
      let direccion = woData.address?.id || this.customAddress?.id;
      if (woData?.proyecto?.direcciones?.length && !direccion) {
        direccion = woData.proyecto?.direcciones[0].id || null;
      }
      const loading = await loadingController.create({
        message: this.$t('creando-parte'),
      });

      await loading.present();
      // TODO: offline, con incidencia

      this.axiosPayload = {
        data: {
          id_incidencia: this.incidence?.id || null,
          id_tecnico: this.gmao.user.id,
          id_direccion: direccion,
          id_sistema: this.customFacility?.id,
          id_proyecto: woData.proyecto?.id || null,
          id_equipo: woData?.team?.id || null,
          id_tipo: woData?.type?.id || null,
          problema: woData.problema,
          fecha: this.$moment(),
          maquinas_anomalias: (this.anomaliesAssets || [])?.map((m) => {
              return {
                  id: m.id,
                  modelo_parte_anomalia: m?.anomalia?.id
              };
          }) || null,
        },
        headers: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      }
        this.$axios
          .post(
            `/v2/users/actions.php?call=setParte&token=${this.gmao.user.token}&v2=1`,
            { data: this.axiosPayload.data },
            this.axiosPayload.headers
          )
          .then(async ({ data }) => {
            /**
             * FIXME:
             * Existe un problema con esto, que hace duplicar llamadas de getParte.
             */
            // await this.handleOfflineWorkorderCreation(data.parte);
            this.$emit('done', data);
            this.$emit('goBack');
            this.workorderData = {};
          }).catch(async (e) => {
            if (e.code == 'ERR_NETWORK' && this.offline.status) {
              await this.handleOfflineWorkorderCreation(woData, e);
            }
          }).finally(async () => await loading?.dismiss());
    },

    async handleOfflineWorkorderCreation(woData, axiosObject = null) {
      try {
        if (!Object.entries(woData).length) {
          throw new Error('No se proporcionaron datos para crear la orden de trabajo.');
        }

        const values = {
          id: this.$moment().unix(),
          offline: '#FF0000',
          id_tecnico: this.gmao.user?.id || 0,
          id_estado_actual: 3,
          id_direccion: woData.address?.id || this.customAddress?.id || 0,
          id_proyecto: woData.proyecto?.id || 0,
          id_tipo: woData.type?.id || 0,
          problema: woData.problema,
          fecha: this.$moment().format("YYYY-MM-DD"),
          created_at: this.$moment().format("YYYY-MM-DD H:m:s"),
          last_modified: this.$moment().format("YYYY-MM-DD H:m:s")
        };

        this.axiosPayload = {
          ...this.axiosPayload,
          callFunction: 'setParte',
          props: {
            layer: 1,
            group: 'partes',
            tempId: values.id,
            dependsOn: [],
            dependencies: []
          }
        };

        /** FIXME: Cambiar para futuro */
        if (!axiosObject) await this.offlineModule.openDBConnection();

        this.offlineModule.syncController.addChange(this.axiosPayload);

        const query = `
          INSERT INTO partes (id, syncObject, id_estado_actual, offline, id_tecnico, id_direccion, id_proyecto, id_tipo, problema, fecha, created_at, last_modified)
          VALUES (
            ${values.id},
            '${JSON.stringify(this.axiosPayload)}',
            ${values.id_estado_actual},
            '${values.offline}',
            ${values.id_tecnico},
            ${values.id_direccion},
            ${values.id_proyecto},
            ${values.id_tipo},
            '${values.problema || ''}',
            '${values.fecha}',
            '${values.created_at}',
            '${values.last_modified}'
          );
          SELECT * FROM partes WHERE id=last_insert_rowid();
        `;

        // Ejecutar la consulta para insertar la nueva orden de trabajo
        const setNewWorkorder = new Promise((resolve) => {
          resolve(
            this.offlineActionsController.SQLiteQuery(
              'createWorkorder_new_workorder',
              'partes',
              query,
              axiosObject?.config || { method: 'post' },
              this.offlineModule?.db
            )
          );
        });

        // Esperar a que se complete la creación de la orden de trabajo
        await setNewWorkorder;

        setTimeout(() => {
          this.$emit('done', { parte: this.offline.workorder });
          this.workorderData = {};
        }, 500);

      } catch (error) {
        // TODO: ErrorController
        if (error.code === 'ERR_NETWORK' && this.offline.status) {
          this.openToastOptions(
            this.$t('no-tiene-conexión-a-internet'),
            this.$t('sus-datos-han-sido-guardados')
          );
        }
        console.error('Error creando orden de trabajo offline:', error);
        // this.$Sentry.captureException(error);
        throw error;
      }
    },
  },
};
</script>