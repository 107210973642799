<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Añadir tiempo') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="$emit('done', true)">{{ $t('Añadir') }}</ion-button>
          </ion-buttons>

        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list>
        <ion-item
          v-if="isTecnicosAdicionales && isMainTechnician"
          @click="$emit('tecnicosModal', true)"
        >
          <ion-label>{{ $t('Técnicos') }}</ion-label>
          <ion-input
            class="ion-text-right"
            :value="techName"
            readonly
            clear-input
          ></ion-input>
        </ion-item>

        <!-- <ion-item
          @click="partidasModal = true"
        >
          <ion-label>{{ $t('Partida presupuestaria') }}</ion-label>

          <ion-input
            class="ion-text-right"
            :value="newHoraBlock.partida ? `${newHoraBlock.partida.codigo} - ${newHoraBlock.partida.descripcion}` : ''"
            readonly
          ></ion-input>
        </ion-item> -->

        <ion-item
          v-for="(h, index) in newHoraBlock.hours"
          :key="`bloque-hora-${index}`"
        >
          <ion-label position="stacked">{{ $t(h.type) }}</ion-label>
          <div style="width: 100%">
            <ion-row>
              <ion-col size="4">
                <ion-input class="ion-text-right datatime" v-model="h.from" type="time">
                  <!-- FIXME: En iOS no sale el icono. Dado que el debug desde el simulador no es lo mas flexible - lo pongo para otra ocasion. -->
                  <!-- <ion-icon v-if='gmao.isMobile && navigator === "undefined"' :icon="timeOutline" /> -->
                </ion-input>
              </ion-col>

              <ion-col size="4">
                <ion-input class="ion-text-right datatime" v-model="h.to" type="time">
                  <!-- FIXME: En iOS no sale el icono. Dado que el debug desde el simulador no es lo mas flexible - lo pongo para otra ocasion. -->
                  <!-- <ion-icon v-if='gmao.isMobile && navigator === "undefined"' :icon="timeOutline" /> -->
                </ion-input>
              </ion-col>

              <ion-col size="4">
                <ion-buttons style="display: flex; justify-content: end">
                  <ion-button
                    size="small"
                    :color="h.id_partidap ? 'warning' : 'medium'"
                    @click="
                      () => {
                        $emit('update:selectedBloque', index)
                        $emit('partidasModal', true)
                      }
                    "
                  >
                    <ion-icon :icon="h.id_partidap ? pricetag : pricetagOutline" />
                  </ion-button>
                  <ion-button
                    size="small"
                    :color="h.id_tipo ? 'primary' : 'medium'"
                    @click="
                      () => {
                        $emit('update:selectedBloque', index)
                        $emit('tiposTiempoModal', true)
                      }
                    "
                  >
                    <ion-icon :icon="hourglassOutline" />
                  </ion-button>
                </ion-buttons>
              </ion-col>
            </ion-row>
          </div>
        </ion-item>

        <ion-item>
          <ion-textarea
            :value="newHoraBlock.observaciones"
            @ionChange="({ target }) => $emit('update:newHoraBlock', target)"
            :label="$t('Observaciones')"
            label-placement="floating"
          ></ion-textarea>
        </ion-item>
      </ion-list>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonInput,
  IonTextarea,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/vue';

import {
  hourglassOutline,
  pricetag,
  pricetagOutline,
  timeOutline,
} from 'ionicons/icons'

import { useGmaoStore } from '@/stores/gmao';

export default {
  name: 'MSetBloqueHora',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newHoraBlock: {
      required: true,
      type: Object,
    },

    techName: {
      required: true,
      type: String,
    },

    selectedBloque: {
      required: true,
      type: String,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    },

    isMainTechnician: {
      required: true,
      type: Boolean,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonInput,
    IonTextarea,
    IonRow,
    IonCol,
    IonIcon,
  },

  setup() {
  const gmao = useGmaoStore();

    return {
      hourglassOutline,
      pricetag,
      pricetagOutline,
      timeOutline,
      gmao,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.datatime {
  background: var(--ion-color-tertiary-alpha);

  ion-icon {
    position: absolute;
    right: 10%;
  }
}
</style>