<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Comunicaciones') }}</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <div>
        <ion-item>
          <ion-textarea
            v-model="newCommunication"
            :label="$t('Mensaje')"
            label-placement="floating"
          ></ion-textarea>
        </ion-item>

        <ion-button expand="full" @click="$emit('done', newCommunication)">
          {{ $t('enviar-mensaje') }}
        </ion-button>
      </div>

      <ion-list style="border-radius: 15px; margin-bottom: 100px">
        <ion-item
          v-for="comunicacion in data"
          :key="`comunicacion-${comunicacion.id}`"
        >
          <ion-label style="white-space: pre-wrap">
            <h3>{{ comunicacion.mensaje }}</h3>
          </ion-label>

          <ion-label slot="end">
            <p style="font-size: 12px; text-align: right">
              {{ comunicacion?.usuario?.nombre_completo }}
            </p>
            <p style="font-size: 10px">
              {{ $moment(comunicacion.created_at).format('LLL') }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>

  </ion-modal>
</template>

<script>
import { ref } from 'vue';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonTextarea,
} from '@ionic/vue';

export default {
  name: 'MSetChatModal',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    data: {
      required: true,
      type: Array,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonTextarea,
  },

  setup() {
    return {
      newCommunication: ref(null),
    };
  },

  methods: {},
};
</script>