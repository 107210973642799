<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Añadir activos') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$emit('done', null)">{{ $t('Añadir') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-searchbar
      class="defaultSearchBar"
      :placeholder="$t('Buscar activos...')"
      v-model="search"
      @ionInput="$emit('search', search)"
      :debounce="1000"
    />

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="border-radius: 15px" lines='full'>
        <ion-item @click="$emit('selectSistema', true)">
          <ion-label>{{ $t('Instalación') }}</ion-label>
          <ion-input
            class="ion-text-right"
            :value="newActivo?.sistema?.nombre"
            readonly
            clear-input
          ></ion-input>
        </ion-item>

        <template v-if="(allSistemasLength || true) && data?.length">
          <ion-item
            v-for="(activo, key) in data"
            :key="`modal-activo-${key}`"
          >
            <ion-checkbox slot="start" v-model="activo.active" label-placement='end'>
              <ion-label>
                <h3>
                  {{ activo?.nombre || `#${activo.id}` }}
                  {{ activo?.modelo?.modelo }} {{ activo?.modelo?.marca }}
                </h3>
                <p>{{ activo.numeroserie }}</p>
                <p>{{ activo.sistema?.nombre }}</p>
                <p>{{ activo.ubicacion }}</p>
                <p>{{ activo.observaciones }}</p>
              </ion-label>
            </ion-checkbox>
          </ion-item>
        </template>
      </ion-list>

      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="$emit('load', $event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más activos...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>

  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonSearchbar,
  IonCheckbox,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';

import { ref } from 'vue';

export default {
  name: 'MSetActivosSistema',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    data: {
      required: true,
      type: Array,
    },

    newActivo: {
      required: true,
      type: Object,
    },

    allSistemasLength: {
      required: true,
      type: Boolean,
    }
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonSearchbar,
    IonCheckbox,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },

  computed: {},

  setup() {

    return {
      search: ref(null),
    };
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}
</style>