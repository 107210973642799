<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95"
  >
    <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Añadir documentos') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button @click="setWorkorderDocuments">{{ $t('Añadir') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <ion-list style="margin-bottom: 60px">
        <ion-item>
          <ion-select :label="`${$t('Tipo')} *`" @ionFocus="getTipos" v-model="documento.tipo">
            <ion-select-option
              v-for="tipo in tiposDoc"
              :key="`tipo-${tipo.id}`"
              :value="tipo.id"
            >
              {{ tipo?.nombre || $t('Sin nombre') }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">{{ $t('Subir fichero') }} *</ion-label>
          <input type="file" @change="getFile" ref="fileDoc" />
        </ion-item>
        <ion-item>
          <ion-input type="text" :label="$t('Nombre')" label-placement='floating' v-model="documento.nombre"></ion-input>
        </ion-item>
        <ion-item>
          <ion-textarea
            v-model="documento.descripcion"
            :label="$t('Description')"
            label-placement="floating"
          ></ion-textarea>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

// Stores
import { useGmaoStore } from '@/stores/gmao';

// EXPORT MODULE
// import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';
import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonInput,
} from '@ionic/vue';

export default {
  name: 'MSetAddWODocument',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    workorder: {
      required: true,
      type: Object,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonInput,
  },

  computed: {},

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      tiposDoc: ref([]),
      documento: ref({}),
      documentFileBase64: ref(null),
      documentFileName: ref(null),

      // Stores
      gmao,
      offline,

      // OTHER
      app,
    };
  },

  methods: {
    // GET
    getTipos() {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getTiposDocumentos',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.tiposDoc = data;
        }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
    },
    // POST
    setWorkorderDocuments() {
      const blob = this.$base64toBlob(
        this.documentFileBase64.split(',')[1],
        this.documentFileBase64.split(';')[0].split(':')[1]
      );

      const formData = new FormData();
      formData.append('parte', this.workorder.id || '');
      formData.append('direccion', this.workorder.id_direccion || '');
      formData.append('tipo', this.documento.tipo || '');
      formData.append('nombre', this.documento?.nombre || '');
      formData.append('descripcion', this.documento.descripcion || '');
      formData.append('cliente', this.workorder.direccion.id_cliente || '');

      formData.append('file', blob, this.documentFileName);

      this.$axios
        .post(
          `/v2/users/actions.php?call=setDocuments&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(({ data }) => {
          if (data.response == 500) {
            this.app.appContext.config.globalProperties.$openToastObject(
              this.$t('error-con-los-permisos'),
              data?.error_message
            );
          } else {
            this.$emit('update:modelValue', false);
            this.$emit('done', null);
            this.documento = {};
          }
        }).catch((error) => {
          // TODO: Offline
          console.log(error);
        });
    },

    // Other
    getFile(ev) {
      const reader = new FileReader();
      reader.readAsDataURL(ev.target.files[0]);
      reader.onload = () => {
        this.documentFileBase64 = reader.result;
      };

      this.documentFileName = ev.target.files[0].name;
    },
  },
};
</script>