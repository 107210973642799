<template>
  <ion-modal :is-open="modelValue" @didDismiss="$emit('update:modelValue', false)"
    :initialBreakpoint="0.95">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">{{ $t('Cancelar') }}</ion-button>
        </ion-buttons>

        <template v-if="!editMode">
          <ion-title>{{ $t('Añadir tiempo') }}</ion-title>
        </template>
        <template v-else>
          <ion-title>{{ $t('Editar tiempo') }}</ion-title>
        </template>

        <ion-buttons slot="end">
          <ion-button :disabled="isTypeMandatory" @click="setTimer">{{ !editMode ? $t('Añadir') : $t('Guardar') }}</ion-button>
        </ion-buttons>

      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
      <!-- NOTE: TIME SECTION -->
      <ion-label class="section-title">
        {{ $t('time') }}
      </ion-label>
      <ion-card class="custom-card">
        <ion-card-content>
          <!-- Subsección: Fecha/tiempo inicio -->
          <ion-item lines="none" class="main-technician">
            <ion-label class="subsection-title">
              {{ $t('start_date_label') }}
            </ion-label>

            <ion-datetime-button datetime="start_date_tracking"></ion-datetime-button>
            <ion-modal class="date-modal" :keep-contents-mounted="true">
              <ion-datetime
                ref="start_date_ref"
                id="start_date_tracking"
                first-day-of-week="1"
                :max="moment().format('YYYY-MM-DD')"
                :value='`${localTimeObject.date_start}T${localTimeObject.time_start}`'
                @ionChange="(e) => processDatetimeValue(e, 'start')"
              ></ion-datetime>
            </ion-modal>
          </ion-item>
          <!-- Subsección: Fecha/tiempo fin -->
          <ion-item lines="none" class="main-technician">
            <ion-label class="subsection-title">
              {{ $t('end_date_label') }}
            </ion-label>

            <ion-datetime-button datetime="end_date_tracking"></ion-datetime-button>

            <ion-modal class="date-modal" :keep-contents-mounted="true">
              <ion-datetime
                id="end_date_tracking"
                first-day-of-week="1"
                :max="moment().format('YYYY-MM-DD')"
                :value='`${localTimeObject.date_end}T${localTimeObject.time_end}`'
                @ionChange="(e) => processDatetimeValue(e, 'end')"
              ></ion-datetime>
            </ion-modal>
          </ion-item>
        </ion-card-content>
      </ion-card>

      <!-- NOTE: TECHNICIAN SECTION -->
      <ion-label class="section-title">
        {{ $t('tecnicos') }}
      </ion-label>
      <ion-card class="custom-card">
        <ion-card-content>
          <!-- Subsección: Técnico Principal -->
          <ion-label class="subsection-title">
            {{ $t('main') }}
          </ion-label>
          <ion-item lines="none" class="main-technician">
            <template v-if='!editMode && isTecnicosAdicionales'>
              <ion-checkbox slot="start" v-model="mainTechnician.checked" @ionChange="handleTechChecks" label-placement='end'>
                <h3>{{ mainTechnician.nombre_completo }}</h3>
              </ion-checkbox>
            </template>
            <template v-else>
              <ion-label>
                <h3>{{ mainTechnician.nombre_completo }}</h3>
              </ion-label>
            </template>
          </ion-item>

          <!-- Subsección: Técnicos Adicionales -->
          <template v-if='((!comportamientos?.tiempos_propio_tecnico && isTecnicosAdicionales)
            || isTecnicosAdicionales) && !editMode'>
            <div class="subsection-header">
              <ion-label class="subsection-title">
                {{ $t('additional') }}
              </ion-label>
              <ion-button class="ion-no-margin checkAll" size="small" fill="outline"
                @click="toggleMarkAll">
                <ion-icon :icon="checkmarkCircleOutline" slot="start"></ion-icon>
                {{ allChecked && $t('des_marcar-todos') || $t('marcar-todos') }}
              </ion-button>
            </div>
            <ion-list class="ion-no-padding">
              <ion-item v-for="(tech, i) in additionalTechnicians?.slice(0, tecnicos_cant)"
                :key="`Techn-${i}`" lines="none">
                <ion-checkbox slot="start" v-model="tech.checked" @ionChange="handleTechChecks" label-placement='end'>

                  <h3>{{ tech.nombre_completo }}</h3>
                </ion-checkbox>
              </ion-item>
              <ion-button
                v-if="(tecnicos_cant <= additionalTechnicians.length) && additionalTechnicians.length > 4"
                class="ion-no-margin" style="display:flex;" size="small" fill="clear"
                color="tertiary" @click="loadMoreTecnicosAd(additionalTechnicians.length / 4 + 1)">
                <span>{{ $t('Ver más') }}</span>
              </ion-button>
            </ion-list>
          </template>
        </ion-card-content>
      </ion-card>

      <!-- NOTE: TIME TYPE SECTION -->
      <ion-label class="section-title">
        {{ $t('Tipo de tiempo') }} <template v-if="behaviourTypeTime">*</template>
      </ion-label>
      <template v-if="hourTypes.length">  
        <ion-card class="custom-card">
          <ion-card-content>
            <ion-list class="ion-no-padding">
              <ion-radio-group
                :compareWith="compareHourTypes"
                :value="localTimeObject.selectedHourType"
                @ionChange="handleTypeChange($event)"
              >
                <ion-item v-for="(type, index) in hourTypes?.slice(0, tiposHora_cant)" :key="index"
                  lines="none">
                  <ion-radio slot="start" :value="type" label-placement="end">
                    <h3>{{ type.nombre }}</h3>
                    <!-- NOTE: Podemos obtener si son horas convenio para informar al técnico -->
                  </ion-radio>
                </ion-item>
              </ion-radio-group>
              <ion-button
                v-if="(tiposHora_cant <= hourTypes.length) && hourTypes.length > 4"
                class="ion-no-margin" style="display:flex;" size="small" fill="clear" color="tertiary"
                @click="loadMoreTiposHora(hourTypes.length / 4 + 1)">
                <span>{{ $t('Ver más') }}</span>
              </ion-button>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>
      <template v-else>
        <ion-card class="custom-card">
          <ion-card-content>
            <ion-text>
              {{ $t('no_hour_types') }}
            </ion-text>
          </ion-card-content>
        </ion-card>
      </template>

      <template v-if="comportamientos?.usar_partidas_en_obras && hasPartidas">
        <ion-label class="section-title">
          {{ $t('Partida presupuestaria') }}
        </ion-label>

        <ion-card class="custom-card">
          <ion-card-content>
            <ion-item
              lines="none"
              class="main-technician"
              @click="$emit('partidasModal', null)"
            >
              <ion-input
                :value="localTimeObject.partida
                  ? `${localTimeObject.partida.codigo} - ${localTimeObject.partida.descripcion}`
                  : ''"
                readonly
                clear-input
                :placeholder="$t('select_budget_item')"
              ></ion-input>
            </ion-item>
          </ion-card-content>
        </ion-card>

      </template>

      <!-- NOTE: TIME TYPE SECTION -->
      <ion-label class="section-title">
        {{ $t('time tracking notes') }}
      </ion-label>
      <ion-card class="custom-card">
        <ion-card-content> 
          <ion-item lines="none" class="main-technician">
            <ion-textarea
              v-model="localTimeObject.observaciones"
              :placeholder="$t('time_tracking_notes_placeholder')"
            ></ion-textarea>
          </ion-item>
        </ion-card-content>
      </ion-card>

      <template v-if="showDeleteAction">
        <ion-card style='margin-top: 0;--ion-background-color: transparent;'>

          <div class="delete-box">
            <ion-button
              style="display:flex;width:100%;"
              fill="clear"
              color="danger"
              :disabled='offline.status'
              @click="resolveAction('delete', localTimeObject)"
            >
              {{ $t('Eliminar') }}
            </ion-button>
          </div>
        </ion-card>
      </template>
    </ion-content>

  </ion-modal>
</template>

<script>
import { ref, watch } from 'vue';

import moment from 'moment';

import { useGmaoStore } from '@/stores/gmao';
import { useOfflineStore } from '@/stores/offline';

import {
  IonModal,
  IonButton,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonItem,
  IonContent,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  // loadingController,
  IonDatetime,
  IonDatetimeButton,
  IonTextarea,
} from '@ionic/vue';
import { checkmarkCircleOutline } from 'ionicons/icons';

export default {
  name: 'MSetAddHoras',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    newHora: {
      required: true,
      type: Object,
    },

    comportamientos: {
      required: false,
      type: Object,
    },

    isTecnicosAdicionales: {
      required: true,
      type: Boolean,
    },

    hasPartidas: {
      required: true,
      type: Boolean,
    },

    deleteItemCallback: {
      type: Function,
      default: () => { }
    },

    additionalTechnicians: {
      required: false,
      type: Array,
    },

    hourTypes: {
      required: true,
      type: Array,
    },
  },

  components: {
    IonModal,
    IonButton,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonItem,
    IonContent,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonRadioGroup,
    IonRadio,
    IonDatetime,
    IonDatetimeButton,
    IonTextarea,
  },

  setup(props) {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const mainTechnician = ref({});
    const allChecked = ref(false);
    const selectedHourType = ref({});

    const tecnicos_cant = ref(0);
    const tecnicos_limit = ref(4);
    const tecnicos_page = ref(1);

    const tiposHora_cant = ref(0);
    const tiposHora_limit = ref(4);
    const tiposHora_page = ref(1);
    
    const localTimeObject = ref({});
    const validTime = ref(false);
    
    const editMode = ref(false);
    const behaviourTypeTime = +gmao.comportamientos?.campo_tipo_tiempo_obligatorio == 1;
    const isTypeMandatory = ref(false);

    watch(
      () => props.modelValue,
      (newVal) => {
        if (newVal) {
          localTimeObject.value = { ...props.newHora };

          prepareTimeObject(localTimeObject.value.edit || false);
          setTechcnician();
          
          loadMoreTecnicosAd();
          loadMoreTiposHora();
        }
      }
    );

    watch(
      () => localTimeObject.value?.selectedHourType,
      () => {
        checkMandatoryType();
      },
      { deep: true }
    );

    const checkMandatoryType = () => {
      isTypeMandatory.value = behaviourTypeTime && !+localTimeObject.value?.selectedHourType?.id;
    }
    
    const setTechcnician = () => {
      mainTechnician.value = gmao.user;

      if (editMode.value) {
        mainTechnician.value = localTimeObject.value.tecnico
      } else if (props.isTecnicosAdicionales) {
        mainTechnician.value.checked = true;
      }
    };

    watch(() => props.newHora?.partida, (newValue) => {
      localTimeObject.value.partida = newValue;
    });

    const prepareTimeObject = (edit) => {
      editMode.value = edit;
      if (edit) {
        localTimeObject.value.selectedHourType = localTimeObject.value.tipo_tiempo;
      }
      else {
        const defaultType = props.hourTypes?.find((type) => type.por_defecto === 1);
        if (defaultType) {
          localTimeObject.value.selectedHourType = defaultType;
        }
      }
      console.log(localTimeObject);
    };

    const loadMoreTecnicosAd = async (page = +tecnicos_page.value) => {
      tecnicos_cant.value = page * +tecnicos_limit.value;

      // let loading = null;
      // if (+page > 1) {
      //   loading = await loadingController.create({
      //     message: t('cargando...'),
      //   });
      //   await loading.present();
      // }

      // await loading?.dismiss();
    };

    const loadMoreTiposHora = async (page = +tiposHora_page.value) => {
      tiposHora_cant.value = page * +tiposHora_limit.value;

      // let loading = null;
      // if (+page > 1) {
      //   loading = await loadingController.create({
      //     message: t('cargando...'),
      //   });
      //   await loading.present();
      // }

      // await loading?.dismiss();
    };

    const toggleMarkAll = () => {
      // NOTE: If editing don't do anything
      if (editMode.value) return;

      allChecked.value = !allChecked.value;
      props.additionalTechnicians.forEach((tech) => {
        tech.checked = allChecked.value;
      });
    };

    return {
      moment,

      gmao,
      offline,

      mainTechnician,
      checkmarkCircleOutline,
      selectedHourType,
      toggleMarkAll,
      allChecked,
      tecnicos_cant,
      tecnicos_limit,
      tecnicos_page,
      loadMoreTecnicosAd,

      tiposHora_cant,
      tiposHora_limit,
      tiposHora_page,
      loadMoreTiposHora,

      validTime,
      localTimeObject,
      
      editMode,
      isTypeMandatory,
      behaviourTypeTime
    };
  },

  computed: {
    showDeleteAction() {
      return this.localTimeObject?.id && this.$hasPermissions('horas', 'borrar');
    },
  },

  methods: {
    handleTechChecks({ target }) {
      this.allChecked = this.additionalTechnicians.every((a) => a.checked) && this.mainTechnician.checked;
      console.log(target, this.allChecked);
      
    },

    compareHourTypes(t1, t2) {
      return t1?.id === t2?.id;
    },

    handleTypeChange({ target }) {
      this.localTimeObject.selectedHourType = target.value;
    },

    processDatetimeValue({ target }, type) {
      if (target) {
        const [date, time] = (target.value || '').split('T');
        if (this.moment(date).isValid() && time?.length) {
          this.localTimeObject[`time_${type}`] = time;
          this.localTimeObject[`date_${type}`] = date;
        }
      }
    },

    async setTimer() {
      let tecnicos = [];

      if (this.isTecnicosAdicionales && !this.editMode) {
        tecnicos = this.additionalTechnicians
          .filter((t) => +t.checked)
          .map((a) => a.id) || [];

        const selectedMainTech = this.mainTechnician.checked && this.mainTechnician
        selectedMainTech?.id && tecnicos.push(selectedMainTech.id);

        tecnicos = [...new Set(tecnicos)];

      } else {
        tecnicos.push(this.mainTechnician.id);
      }

      if (!tecnicos.length) {
        this.$openToastObject(
          this.$t('generic_timer_error'),
          this.$t('timer_error_missing_techs'),
          'danger',
          'bottom',
          '4000'
        );
        return;
      }

      let isValid = false;
      isValid = await this.$isTimeValid(
        {
          inicio: this.localTimeObject.time_start,
          fin: this.localTimeObject.time_end,
        },
        {
          desde: this.localTimeObject?.date_start,
          hasta: this.localTimeObject?.date_end
        }
      );

      const data = {
        ...this.localTimeObject,
        users: tecnicos.toString()
      };

      /** NOTE: En caso contrario lo notificamos desde la función $isTimeValid(...) */
      isValid && this.$emit('done', data);
    },

    resolveAction(action = '', hour) {

      if (action === 'delete') {
        this.$showDeleteAlertController(
          undefined,
          undefined,
          () => this.deleteItemCallback(hour),
        );
      } else {
        this.$openToastObject(
          this.$t('Ha ocurrido un error'),
          this.$t('generic-delete-error-message')
        );
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .date-modal {
    background-color: rgba(0, 0, 0, 0.4);
    --width: 290px;
    --height: 382px;
    --border-radius: 8px;
  }

  ion-modal.date-modal::part(content) {
    height: fit-content;
  }

  .date-modal ion-datetime {
    width: 100% !important;
  }

  .delete-box {
    display: flex;
    justify-content: center;
  }

  ion-input[field-type="type"] {
    text-align: right;
  }

  ion-card {
    margin-bottom: 20px;
    margin-top: 12px;
    margin-inline: 0;
    border-radius: 12px;
    --ion-background-color: #fff;
    box-shadow: none;

    ion-card-content {
      padding-block: 10px;
      padding-inline: 16px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  ion-content {
    --ion-background-color: #f6f6f6;
  }

  ion-item {
    margin-bottom: 0;

    &::part(native) {
      padding-left: 0;

      .item-inner {
        padding: 0;
      }
    }
  }

  .section-title {
    font-size: 11pt !important;
    font-weight: 500;
    margin-bottom: 18px;
    color: #767676;
    text-transform: capitalize;
  }

  .subsection-title {
    margin: 12px 0 8px;
    text-transform: capitalize;
    text-wrap: nowrap;
  }

  .subsection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ion-button.checkAll {
    font-size: 12px;
    --border-width: 1px;
  }

</style>