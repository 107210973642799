import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import './registerServiceWorker';

import { IonicVue, loadingController } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/gmao.css';
import './theme/transitions.css';

/* Virtual Scroller */
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller';

/* Axios */
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

/* Moment */
// import * as moment from 'moment';

/* Timezone */
import moment from 'moment-timezone';

/* Pinia */
import { createPinia } from 'pinia'

/* Signature */
import VueSignaturePad from 'vue-signature-pad';

/* i18n */
import { createI18n } from 'vue-i18n';

/* Capacitor Camera */
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { Capacitor } from '@capacitor/core';

// Network API
import { Network } from '@capacitor/network';

/** GEOLOCATION */
import { Geolocation } from '@capacitor/geolocation';

/* PWA Elements */
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);


// Toast Controller
import { toastController } from '@ionic/vue';

// Alert Controller
import { alertController } from '@ionic/vue';

/* Compressor JS */
import Compressor from 'compressorjs';

// Sentry
import * as Sentry from '@sentry/vue';

import { timeObject, dateObject } from '@/utils/Interfaces/IsTimeValidStructure'

// OneSignal
import OneSignal from 'onesignal-cordova-plugin';

// OfflineModule
// import { OfflineModule } from '@/utils/OfflineModule/OfflineModule';

import messages from '@/i18n';

const i18n:any = createI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})

import mixpanel from 'mixpanel-browser';

import LogRocket from 'logrocket';
LogRocket.init('yyidmf/gmao-cloud-tecs');

const app = createApp(App)
  .use(IonicVue, {
    innerHTMLTemplatesEnabled: true,
  })
  .use(router)
  .use(VueSignaturePad)
  .use(i18n)
  .use(VueVirtualScroller)
  .use(createPinia());


type Permission = {
  todos: number,
  ver: number,
  crear: number,
  editar: number,
  borrar: number,
};

// PINIA GMAO
import { useGmaoStore } from '@/stores/gmao';
const gmao: any = useGmaoStore();

gmao.updateCacheVersion();

// Check Platform and init stores
const platform = Capacitor.getPlatform();

// PINIA TRADUCCIONES
import { useLangStore } from '@/stores/lang';
const lang: any = useLangStore();

i18n.global.locale = gmao.user?.lang || navigator.language || 'en'

// SOBREESCRIBIR LA FUNCIÓN $t DE i18n PARA INCLUIR TRADUCCIONES
const t = i18n.global.t;
i18n.global.t = (key: string | undefined = '') => {
  return lang?.trans && lang?.trans?.find((trans: any) => trans?.campo == key)?.mensaje || t(key);
}

axios.defaults.params = {}
axios.defaults.params['version'] = gmao.v;

axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  // request.params.hash = "Hash de prueba";

  return request;
});

axios.interceptors.response.use((response) => {
  // console.log(response);
  
  if(response?.data?.error == '500') {
    console.log(response?.data?.message);
  }

  if (response?.config?.params?.call == 'getParte') {
    // TODO: Seteamos de manera global un objeto que relaciona el modelo con el hash
  }

  return response;
});

app.provide('axios', axios);

/* Onesignal */
document.addEventListener('deviceready', async () => {
  console.log('Device is ready - Initializing OneSignal');

  try {
    // Inicialización de OneSignal
    OneSignal.initialize('045d387e-728a-4aab-bb7c-4565ed97b041');
    console.log('OneSignal initialized.');

    // Solicitar permiso para notificaciones
    const permissionStatus = await OneSignal.Notifications.permissionNative();
    console.log('Notification permission status:', permissionStatus);

    // Listener para eventos de clic en notificaciones
    OneSignal.Notifications.addEventListener('click', (event) => {
      console.log('Notification clicked:', event);
    });

    // Listener para cambios en la suscripción
    OneSignal.User.pushSubscription.addEventListener('change', (subscription:any) => {
      if (subscription && subscription?.id) {
        // console.log('Player ID (from subscription change):', subscription?.id);
      } else {
        console.error('Error al obtener la subscripcion', JSON.stringify(subscription, null, 2));
      }
    });
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Error initializing OneSignal: ${error.message}`);
      console.error(`Error stack: ${error.stack}`);
    } else {
      console.error('Error initializing OneSignal:', JSON.stringify(error, null, 2));
    }
  }
});

/// Inicializamos Sentry
Sentry.init({
  dsn: "https://c008d84b9ff0ac7d5a0908e81ce1010f@sentry.tecneca.com/3",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, 
  tracePropagationTargets: ["localhost", /^https:\/\/app\.gmaocloud\.es/],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0,
});

/* Global vars */
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$oneSignal = OneSignal;
app.config.globalProperties.$Camera = Camera;
app.config.globalProperties.$CameraResultType = CameraResultType;
app.config.globalProperties.$CameraSource = CameraSource;
app.config.globalProperties.$Sentry = Sentry;
app.config.globalProperties.$moment = (_dateInput = moment(), format:boolean = false) => {

  const dateFormats = (locale:string) => {
    let l = null;
    switch (locale) {
      case 'en-US':
      case 'en':
        l = 'MM/DD/YYYY';
        break;

      default:
        l = 'DD/MM/YYYY';
        break;
    }
    return l;
  };

  const locale = navigator.language || 'es';
  moment.locale(locale);

  if (format) return moment(_dateInput, dateFormats(locale));
  return moment(_dateInput);
};

app.config.globalProperties.$objectToFormData = (data: object) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formdata.append(key, (value || ''));
  }

  return formdata;
}

app.config.globalProperties.$showDeleteAlertController = async (
  header: string = t('generic_delete_alert_title'),
  message: string = t('generic_delete_alert_message'),
  callback: () => void,
  { cancel, confirm } = { cancel: 'cancelar', confirm: 'yes_delete' }
) => {
  const alert = await alertController.create({
    cssClass: 'my-custom-class',
    header,
    message: t(message),
    buttons: [
      {
        text: t(cancel),
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
      },
      {
        text: t(confirm),
        id: 'confirm-button',
        handler: callback,
      },
    ],
  });

  await alert.present();
}

app.config.globalProperties.$alertController = async (header: string, message: string|undefined = undefined, callback: () => void,
  { cancel, confirm } = { cancel: 'cancelar', confirm: 'Confirmar' }
) => {
  const alert = await alertController.create({
    cssClass: 'my-custom-class',
    header,
    message: message,
    buttons: [
      {
        text: cancel,
        role: 'cancel',
        cssClass: 'secondary',
        id: 'cancel-button',
      },
      {
        text: confirm,
        id: 'confirm-button',
        handler: callback,
      },
    ],
  });

  await alert.present();
}

/**
 * XXX: NOTE: Simulación del controlador de errores de la aplicacion.

 * A futuro, cuando se haga la migración con el nuevo offline -> migrar esta funcion alli
 * REVIEW: IDEA -> Pasar el control del offline de las vistas a un controlador a parte mediante esta funcion.
 */
app.config.globalProperties.$errorController = async (e: AxiosError | undefined = undefined, error: boolean = true) => {
  console.log('PROXIMAMENTE IMPLEMENTACION: ', e);

  if (!error) {
    /**
     * NOTE: Esto es temporal. Sirve para mostrar un mensaje de un registro recien borrado.
     */
    await app.config.globalProperties.$openToastObject(
      t('delete_success_title'),
      undefined,
      'success',
      'bottom'
    );
  } else {
    await app.config.globalProperties.$openToastObject(t('Ha ocurrido un error'), t('Ha habido un error al procesar las solicitud'), 'danger', 'bottom');
  }
}

/** FIXME: ANALOGO DE LA FUNCION getGeolocation en TimeToggle.vue */

/**
 * CHECK GEO PERMISSIONS: asks for Geolocation permissions regarding the device type.

 * => Technical POV:
 *    - Firstly we check for the Geolocation permissions.
 *      -> IF 'granted': return true
 *      -> Otherwise (denied, prompt):
 *        + We show a Toaster Object <- (error message)
 *        + We check for the device and request permissions. FIXME: *** *Does this make sense?

 */
app.config.globalProperties.$checkGeoPermissions = async (interruptProcess:boolean = true) => {
  let permissions:any = await Geolocation.checkPermissions();

  if (permissions?.location === 'granted') return true;

  if (['denied', 'prompt'].includes(permissions?.location)) {
    try {

      /** Mostramos al usuario un mensaje que no tiene permisos y los necesita
       *  para seguir usando la app.
       */
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('error-con-los-permisos'),
        `${i18n.global.t('no-tienes-permisos-de-geolocalizacion')}`,
        'danger',
        'top',
        '4000',
        [
          {
            text: i18n.global.t('help'),
            role: 'cancel',
            handler: () => {
              window.open('https://gmao-cloud.notion.site/WEB-v2-Permitir-permisos-geolocalizaci-n-c0abcf9f429943fba6ab461e1ea020c7');
            }
          }
        ]
      );

      /**
       * Si están negados los permisos.
       * Los volvemos a pedir segun el tipo de dispositivo
       *  -> Catch(...): lanzamos excepcion para informar al usuario de que no tiene permisos...
       */

      if (interruptProcess) {
        // FIXME: *** *REVISAR: Esto es raro, porque si no tengo permisos y los vuelvo a solicitar va a fallar siempre...
          // No se hasta que punto eso tiene sentido...
        if (typeof navigator === 'undefined') {
          permissions = await Geolocation.requestPermissions();
        } else {
          permissions = await new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, async (e) => {

            app.config.globalProperties.$Sentry.captureException(`WEB:checkGeoPermissions::${e.message}`);

            reject(new Error(e.message));

          }));
        }

        // FIXME: *** *REVISAR: si es objeto de permisos -> true
        return !!permissions;
      }

      return false;

    } catch (error) {
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('error-con-los-permisos'),
        i18n.global.t('no-tienes-permisos-de-geolocalizacion'),
        'danger',
        'top',
        '4000',
        [
          {
            text: i18n.global.t('help'),
            role: 'cancel',
            handler: () => {
              window.open('https://gmao-cloud.notion.site/WEB-v2-Permitir-permisos-geolocalizaci-n-c0abcf9f429943fba6ab461e1ea020c7');
            }
          }
        ]
      );

      app.config.globalProperties.$Sentry.captureException(new Error(`Error:checkGeoPermissions::${error}`));
      if (interruptProcess) throw new Error(`Error:checkGeoPermissions::${error}`); // FIXME: Se usa para cortar toda ejecucion posterior. Motivo: TimeToggle.vue
    }
  }

};

/**
 * GET GEOLOCATION: returns a GeolocationPosition class of the device.

 * => Technical POV:
 *    - We create two promises (geolocationPromise, timeoutPromise):
 *      1. 'geolocationPromise' is for the Geolocation position (with device distinction)
 *      2. 'timeoutPromise' is for the Timeout dismiss -> loadingController.
 *    - Then we do a Promise.race() to resolve the promise that finishes first.
 *
 *    + With this approach if any error occurs OR it takes too long (TIMEOUT) to get the GEO position
 *      It will send an Error to Sentry and dismiss the loadingController, so user can continue with the work.

 *    + If previous to get the Geolocation we need to check for the permissions -> checkPermissions=true
        If the user does not have the permissions it won't get any GEO position.

 *    ** Regarding the 'checkPermissions' flag. This is done because in the app sometimes we directly do not
          check for the permissions - we get the position straightforward: e.g when open a WO

 * @param checkPermissions
 * @returns GeolocationPosition position
 */
app.config.globalProperties.$getGeolocation = async (checkPermissions: boolean = false, interruption:boolean = true): Promise<any> => {
  if (checkPermissions && !(await app.config.globalProperties.$checkGeoPermissions(interruption))) {return;}

  const loader = await loadingController.create({
    message: i18n.global.t('Geolocalizando'),
  });
  await loader.present();

  const TIMEOUT = 10000;
  const device = platform;

  const geolocationPromise = new Promise((resolve, reject) => {
    if (device.includes('mobile') || device.includes('mobileweb')) {
      resolve(Geolocation?.getCurrentPosition());
    } else return navigator.geolocation.getCurrentPosition(resolve, reject);

  });

  const timeoutPromise = new Promise((_, reject) => setTimeout(() => {
    reject(new Error('Geolocation Timeout'));
  }, TIMEOUT));

  try {
    const position = await Promise.race([geolocationPromise, timeoutPromise]);

    // NOTE: DEBUG
    // console.log('$getGeolocation::POSITION: ', position);

    return position;
  } catch (err) {
    let networkInfo = { connected: navigator.onLine, connectionType: 'unknown' };
    
    if (Capacitor.isNativePlatform()) networkInfo = await Network.getStatus();
    else if ((navigator as any).connection) {
      networkInfo = {
        connected: navigator.onLine,
        connectionType: (navigator as any).connection?.type || (navigator as any).connection?.effectiveType || 'unknown',
      };
    } else {
      networkInfo = {
          connected: navigator.onLine,
          connectionType: 'unknown'
      };
    }

    const geoPermissionStatus = await app.config.globalProperties.$checkGeoPermissions(interruption);
    
    app.config.globalProperties.$Sentry.captureException(err, {
      extra: {
        errorType: 'Geolocation Timeout',
        networkInfo,
        permissions: {
          geolocationGranted: geoPermissionStatus,
        },
      },
    });
    
    return {};

  } finally {
    /** En caso de que falle o no siempre llegará aquí. */
    await loader?.dismiss();
  }

};

/* Validate time/date */
app.config.globalProperties.$isTimeValid = async (time: timeObject, date:dateObject) => {
    const startTime = time.inicio;
    const endTime = time.fin;

    const startDateTime = moment(date?.desde);
    const endDateTime = moment(date?.hasta);

    const isEndBeforeStartHour = moment(endTime, 'hh:mm').isBefore(moment(startTime, 'hh:mm'));
    const isEndBeforeStartDate = moment(endDateTime, 'YYYY-MM-DD').isBefore(moment(startDateTime, 'YYYY-MM-DD'));
    const isSameDate = startDateTime.isSame(endDateTime, 'day');


    if (isEndBeforeStartHour && isSameDate) {
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('Ha ocurrido un error'),
        i18n.global.t('El tiempo de fin no puede ser menor al de inicio'), 'danger', 'bottom');
      return false;
    }
    if (isEndBeforeStartDate) {
      await app.config.globalProperties.$openToastObject(
        i18n.global.t('Ha ocurrido un error'),
        i18n.global.t('La fecha de fin no puede ser menor a la de inicio'), 'danger', 'bottom');
      return false;
    }


    return true;

    // Edit
    // if (this.newHora.id) {
    //   if (this.validTime) {
    //     if(validateTo) {
    //       console.log('VALIDATE');
    //       if(validateTo == 'from') {
    //         this.$emit('update:newHora', { from: value });
    //       } else {
    //         this.$emit('update:newHora', { to: value });
    //       }
    //     } else {
    //       console.log('NO VALIDATE');
    //       this.$emit('update:newHora', { from: value });
    //       this.$emit('update:newHora', { to: value });
    //     }
    //   }
    // }
  };

app.config.globalProperties.$base64toBlob = (b64Data: string, contentType = 'image/png', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

app.config.globalProperties.$appVersion = () => {
  return +(gmao.v || '0').replace(/\./g, '');
};

app.config.globalProperties.$timeFormatter = (time: number) => {
  return {
    hours: Math.floor(time / 3600),
    minutes: String(Math.floor((time % 3600) / 60)).padStart(2, '0'),
    seconds: String((time % 3600) % 60).padStart(2, '0')
  }
};

app.config.globalProperties.$getBase64Image = async (url: string, contentType = 'image/png') => {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if(ctx) {
        ctx.drawImage(img, 0, 0);
        const data = canvas.toDataURL(contentType);
        resolve(data);
      }
    }
  });
}

app.config.globalProperties.$compressImage = async (file: File | Blob, resolve: () => void, reject: () => void) => {
  new Compressor(file, {
    quality: 1,
    maxWidth: 1024,

    success: resolve,
    error: reject
  });
}

app.config.globalProperties.$checkPermissions = (data: Permission, permission: string) => {
  switch(permission) {
    case 'ver': return data?.todos || data?.ver;
    case 'crear': return data?.todos || data?.crear;
    case 'editar': return data?.todos || data?.editar;
    case 'borrar': return data?.todos || data?.borrar;
  }

  return false;
};

app.config.globalProperties.$hasPermissions = (label: string, permission: string) => {
  const permisos = gmao?.user?.permisos;
  const permisosG = gmao?.user?.permisosG;

  if(typeof permisos?.[label]?.[permission] !== 'undefined' || typeof permisos?.[label]?.todos !== 'undefined') {
    return permisos?.[label]?.[permission] || permisos?.[label]?.todos;
  }

  return permisosG?.[label]?.[permission] || permisosG?.[label]?.todos;
}

app.config.globalProperties.$openToastObject = async (header:string, message: string = '', color:any = 'danger', position:any = "top", duration:any = '4000', buttons:any = []) => {
  const toast = await toastController.create({
    header,
    message,
    position,
    color,
    duration,
    buttons
  });

  await toast.present();
}


mixpanel.init('e472b8bb4256015d9db3e730738f51ee', {
  autocapture: true,
  debug: true, // Para que muestre logs en consola
  track_pageview: true
});

app.config.globalProperties.$mixpanel = mixpanel;

window.addEventListener('error', (event) => {
  mixpanel.track('App Crash', {
    message: event.message,
    filename: event.filename,
    lineno: event.lineno,
    colno: event.colno
  });
});

window.addEventListener('error', (event) => {
  LogRocket.captureException(event.error);
});

// Ejemplo de como trackear diferentes acciones
// LogRocket.track('Button Clicked', {
//   buttonName: 'Start Process',
//   screen: 'Dashboard'
// });

Sentry.setContext("character", {
  user: gmao?.user?.email,
  server: gmao?.workspace?.descripcion,
  version: gmao?.v,
});


if (gmao?.user?.id) {
  
  // Get network status
  let networkInfo = { connected: navigator.onLine, connectionType: 'unknown' };
  if (Capacitor.isNativePlatform()) {
    networkInfo = await Network.getStatus();
  } else if ((navigator as any).connection) {
    networkInfo = {
      connected: navigator.onLine,
      connectionType: (navigator as any).connection?.type || (navigator as any).connection?.effectiveType || 'unknown',
    };
  }

  // Get geolocation permissions and status
  const geoStatus = {
    permissions: 'unknown',
    locationEnabled: false
  };
  
  try {
    const geoPermissions = await Geolocation.checkPermissions();
    geoStatus.permissions = geoPermissions.location;
    
    // Try to get current position to check if location is enabled
    try {
      await Geolocation.getCurrentPosition();
      geoStatus.locationEnabled = true;
    } catch (error) {
      geoStatus.locationEnabled = false;
    }
  } catch (error) {
    console.error('Error checking geolocation status:', error);
  }

  // // Initialize offline module and wait for it to be ready
  // const offlineModule = OfflineModule.getInstance();
  // await offlineModule.initDBConnection();
  
  // // Get offline status after initialization
  // const offlineStatus = offlineModule.getOfflineStatus();
  // const downloadMetrics = offlineModule.syncController.getDownloadMetrics();

  // Prepare offline metrics
  // const offlineMetrics = {
  //   initialization: {
  //     isInitialized: offlineStatus.isInitialized,
  //     dbAccessible: offlineStatus.dbAccessible,
  //     hasErrors: !!offlineStatus.lastInitError,
  //     errorMessage: offlineStatus.lastInitError?.message || null
  //   },
  //   dataSync: {
  //     isComplete: offlineStatus.dataDownload.isComplete,
  //     duration: offlineStatus.dataDownload.duration,
  //     startTime: new Date(offlineStatus.dataDownload.startTime).toISOString(),
  //     endTime: offlineStatus.dataDownload.endTime ? new Date(offlineStatus.dataDownload.endTime).toISOString() : null,
  //     interruptedAt: offlineStatus.dataDownload.interruptedAt,
  //     tablesStatus: {
  //       total: downloadMetrics.totalTables,
  //       completed: downloadMetrics.completedTables,
  //       failed: downloadMetrics.failedTables,
  //       failureCount: downloadMetrics.errors.length
  //     }
  //   },
  //   connection: {
  //     isConnected: offlineStatus.connection.isConnected,
  //     type: offlineStatus.connection.type,
  //     lastChecked: new Date(offlineStatus.connection.lastChecked).toISOString()
  //   }
  // };

  mixpanel.identify(gmao.user.id);
  (mixpanel as any).people.set({
    $name: gmao.user?.nombre_completo,
    $email: gmao.user?.email,
    Server: gmao?.workspace?.descripcion || 'localhost',
    Version: gmao?.v,
    Modelo: platform === 'web' ? window.navigator.userAgent : platform,
    // Add new tracking properties
    Network: {
      isConnected: networkInfo.connected,
      connectionType: networkInfo.connectionType
    },
    Geolocation: {
      permissions: geoStatus.permissions,
      locationEnabled: geoStatus.locationEnabled
    },
    // OfflineStatus: offlineMetrics
  });

  LogRocket.identify(gmao.user.id, {
    name: gmao.user?.nombre,
    email: gmao.user?.email,
    // Add new tracking properties
    // networkStatus: networkInfo,
    // geolocationStatus: geoStatus,
    // offlineStatus: offlineStatus
  });

  // Track offline initialization as a separate event
  // mixpanel.track('Offline Module Initialization', {
  //   success: offlineMetrics.initialization.isInitialized,
  //   dbAccessible: offlineMetrics.initialization.dbAccessible,
  //   syncDuration: offlineMetrics.dataSync.duration,
  //   tablesCompleted: offlineMetrics.dataSync.tablesStatus.completed,
  //   tablesFailed: offlineMetrics.dataSync.tablesStatus.failed.length,
  //   timestamp: new Date().toISOString()
  // });
}


router.isReady().then(() => {
  app.mount('#app');
});