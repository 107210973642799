<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">
            {{ $t('Cancelar') }}
        </ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Abrir nueva incidencia') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" style="height:calc(100% - 80px)">

      <ion-item v-if="item">
        <ion-icon
          class="f30" slot="start" :icon="qrCodeOutline"
        ></ion-icon>

        <ion-label>
          <div>{{ $t('Activo') }}</div>
          <div>{{ item.nombre }} - {{ item?.modelo?.modelo || 'Sin modelo' }} - {{
            item?.modelo?.marca || 'Sin marca' }}</div>
        </ion-label>
      </ion-item>

      <ion-item v-if="!+gmao.comportamientos.ocultar_gama && !isWorkorder">
        <ion-label>{{ $t('Especialidad') }}</ion-label>
        <template v-if='+gmao.comportamientos.speciality_mandatory_incidence_modal_tecs'>
          <span style="color: var(--ion-color-danger)">*</span>
        </template>

        <ion-input
          class="ion-text-right"
          :value="newIncidence?.gama?.nombre"
          readonly
          clear-input
          @click="() => {
            applyFiltersGamas(true)
            setGama = true;
          }"
        ></ion-input>
      </ion-item>

      <ion-item v-if="newIncidence?.gama?.id && gamas.flat()?.length && !+gmao.comportamientos.ocultar_gama && !isWorkorder">
        <ion-label>{{ $t('Sub especialidad') }}</ion-label>

        <ion-input
          class="ion-text-right"
          :value="newIncidence?.subgama?.nombre"
          readonly
          clear-input
          @click="setSubGama = true"
        ></ion-input>
      </ion-item>


      <ion-item v-if="!+gmao.comportamientos?.zonas_incidencia"  @click="setZonas = true">
        <ion-label>{{ $t('Zonas') }}</ion-label>
        <ion-textarea
          class="ion-text-right"
          :value="newIncidence?.zonas?.map(a => a.nombre).toString()"
          :autoGrow="true"
          readonly
        >
        </ion-textarea>
      </ion-item>

      <ion-item :disabled="item"
        v-if="(addresses.flat()?.length == 1 && !+gmao.comportamientos.ocultar_direccion) || (addresses.flat()?.length > 1 && (!!+gmao.comportamientos.ocultar_direccion || !+gmao.comportamientos.ocultar_direccion))"
        @click="setAddress = true">
        <ion-label>{{ $t('Dirección') }}</ion-label>
        <ion-input class="ion-text-right"
          :value="addresses.flat().find((a) => a?.id == newIncidence.id_direccion)?.nombre" readonly
          clear-input></ion-input>
      </ion-item>

      <ion-item :disabled="item" v-if="newIncidence?.address?.id && !+gmao.comportamientos.no_instalacion_activos_cli && !isWorkorder">
        <ion-label>{{ $t('Instalación') }}</ion-label>

        <ion-textarea
          class="ion-text-right"
          :value="newIncidence?.sistema?.nombre"
          readonly
          clear-input
          @click="setIncidenceSistema = true"
          :autoGrow="true"
        ></ion-textarea>
      </ion-item>

      <ion-item v-if="newIncidence?.sistema?.id && !isWorkorder">
        <ion-label>{{ $t('Activo') }}</ion-label>
        <template v-if='+gmao.comportamientos.asset_mandatory_incidence_modal_tecs'>
          <span style="color: var(--ion-color-danger)">*</span>
        </template>

        <ion-input
          :disabled="!newIncidence.sistema"
          class="ion-text-right"
          :value="newIncidence?.maquinas?.map((m) => m.nombre).toString()"
          readonly
          clear-input
          @click="setIncidenceMaquina = true"
        ></ion-input>
      </ion-item>

      <ion-item v-if="!+gmao.comportamientos.ocultar_prioridad && !isWorkorder">
        <ion-select :label="$t('Prioridad')" v-model="newIncidence.prioridad" slot="end">
          <ion-select-option
            v-for="priority in priorities"
            :key="`priority-${priority.value}`"
            :value="priority.value"
          >
            {{ `${priority.value} - ${$t(priority.label)}` }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-textarea
          v-model="newIncidence.problema"
          :label="$t('Description')"
          label-placement="floating"
        ></ion-textarea>
      </ion-item>

      <ion-item lines="none">
        <ion-label>{{ $t('Imagen') }}</ion-label>
        <ion-buttons>
          <ion-button
            size="small"
            fill="clear"
            color="tertiary"
            @click="addPhotoIncidence()"
          >
            <ion-icon :icon="camera"></ion-icon>
          </ion-button>

          <ion-button
            size="small"
            fill="clear"
            color="dark"
            @click="galleryPicker()"
          >
            <ion-icon :icon="imagesOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>

      <ion-button expand="full" @click="createIncidence(newIncidence)">
        {{ $t('Crear Incidencia') }}
      </ion-button>
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="setAddress" @didDismiss="setAddress = false">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setAddress = false">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('seleccionar-direccion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar
        :placeholder="$t('buscar-direccion')"
        v-model="addressSearchAjax"
        @ionInput="getSearchAddresses(true)"
        :debounce="1000"
      />

      <ion-list>
        <ion-item
          v-for="address in computedAddresses"
          :key="`address-${address.id}`"
          @click="
            () => {
              newIncidence.address = address;
              setAddress = false;
              getSistemas();
            }
          "
        >
          <ion-label>
            <h3>{{ address.nombre }}</h3>
            <p>{{ address.direccion }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="onLoadDirecciones($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más direcciones...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="setGama" @didDismiss="setGama = false">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setGama = false">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('Seleccionar especialidad') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar
        :placeholder="$t('Buscar especialidad...')"
        v-model="especialidadSearch"
        @ionInput="applyFiltersGamas(true)"
        :debounce="1000"
      />

      <ion-list>
        <ion-item
          @click="
            () => {
              newIncidence.gama = null;
              newIncidence.subgama = null;
              setGama = false;
            }
          "
        >
          <ion-label>
            {{ $t('Sin especialidad') }}
          </ion-label>
        </ion-item>

        <template v-if="gamas.length">
          <ion-item
            v-for="gama in gamas.flat()"
            :key="`gama-${gama.id}`"
            @click="
              () => {
                newIncidence.gama = gama;
                setGama = false;
                applyFiltersGamas(true, newIncidence?.gama?.id);
              }
            "
          >
            <ion-label>
              <h3>{{ gama.nombre }}</h3>
            </ion-label>
          </ion-item>
        </template>
      </ion-list>
      <ion-infinite-scroll
          threshold="100px"
          id="infinite-scroll"
          @ionInfinite="onLoadGamas($event)"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            :loading-text="$t('Cargando más especialidades...')"
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="setSubGama" @didDismiss="setSubGama = false">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setSubGama = false">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('Seleccionar sub-especialidad') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar
        :placeholder="$t('Buscar sub-especialidad...')"
        v-model="subEspecialidadSearch"
        @ionInput="applyFiltersGamas(true, newIncidence.gama?.id)"
        :debounce="1000"
      />

      <ion-list>
        <ion-item
          @click="
            () => {
              newIncidence.subgama = null;
              setSubGama = false;
            }
          "
        >
          <ion-label>
            {{ $t('Sin sub especialidad') }}
          </ion-label>
        </ion-item>

        <template v-if="gamas?.length">
          <ion-item
            v-for="gama in gamas.flat()"
            :key="`gama-${gama.id}`"
            @click="
              () => {
                newIncidence.subgama = gama;
                setSubGama = false;
              }
            "
          >
            <ion-label>
              <h3>{{ gama.nombre }}</h3>
            </ion-label>
          </ion-item>
        </template>

      </ion-list>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setIncidenceSistema"
    @didDismiss="setIncidenceSistema = false"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setIncidenceSistema = false">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>

        <ion-title>{{ $t('selecciona-una-instalacion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar
        :placeholder="$t('Buscar instalación...')"
        v-model="sistemaSearchAsset"
      />

      <ion-list style="border-radius: 15px">
        <ion-item
          @click="
            () => {
              newIncidence.sistema = null;
              setIncidenceSistema = false;
            }
          "
        >
          {{ $t('Ningúna Instalación') }}
        </ion-item>
        <!-- <template v-if="sistemas.length">
          <ion-item
            v-for="s in sistemas"
            :key="`sistema-${s.id}`"
            @click="
              () => {
                newIncidence.sistema = s;
                setIncidenceSistema = false;
                applyFiltersMaquinas(true)
              }
            "
          >
            {{ `${s?.nombre || 'Sin nombre'}` }}
          </ion-item>
          <SystemTree :systems="sistemas.flat()" @done="(sistema) => {
            newIncidence.sistema = sistema;
            setIncidenceSistema = false;
            applyFiltersMaquinas(true)
          }"  />

        </template> -->
        <template v-if="$appVersion() > 148">
          <ion-item
            v-for="(s) in computedSistemas"
            :key="`sistema-${s.id}`"
            @click="
              () => {
                newIncidence.sistema = s;
                setIncidenceSistema = false;
                applyFiltersMaquinas(true)
              }
            "
          >
            {{ `${s?.nombre || 'Sin nombre'}` }}
          </ion-item>
        </template>

        <template v-else> <!-- VERSIONES ANTIGUAS A LA 1.4.8 -->
          <SystemTree :systems="sistemas.flat()" @done="(sistema) => {
              newIncidence.sistema = sistema;
              setIncidenceSistema = false;
              applyFiltersMaquinas(true)
            }"
          />
        </template>

      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="onLoadSistemas($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más activos...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <ion-modal
      :is-open="setIncidenceMaquina"
      @didDismiss="setIncidenceMaquina = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="setIncidenceMaquina = false">{{ $t('Cancelar') }}</ion-button>
          </ion-buttons>
          <ion-title>{{ $t('Seleccionar activos') }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="setIncidenceActives">{{ $t('Añadir') }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <ion-searchbar
          :placeholder="$t('Buscar activos...')"
          v-model="activeSearchAsset"
          @ionInput="applyFiltersMaquinas(true)"
          :debounce="1000"
        />

        <ion-list style="border-radius: 15px" lines='full'>
          <ion-item
            @click="() => {
              newIncidence.maquina = null
              setIncidenceMaquina = false;
            }"
          >
            {{ $t('Sin Maquina') }}
          </ion-item>

          <template v-if="maquinas.length">
            <ion-item v-for="(activo, key) in maquinas.flat()" :key="`modal-activo-${key}`">
              <ion-checkbox slot="start" v-model="activo.active" label-placement='end'>
                <ion-label>
                  <p>{{ activo.sistema?.nombre }}</p>
                  <h3>
                    {{ activo.nombre || `#${activo.id}` }}
                    {{ activo?.modelo?.modelo }} {{ activo?.modelo?.marca }}
                  </h3>
                  <p>{{ activo.numeroserie }}</p>
                  <p>{{ activo.ubicacion }}</p>
                  <p>{{ activo.observaciones }}</p>
                </ion-label>
              </ion-checkbox>
            </ion-item>
          </template>
        </ion-list>
        <ion-infinite-scroll
          threshold="100px"
          id="infinite-scroll"
          @ionInfinite="onLoadMaquinas($event)"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            :loading-text="$t('Cargando más activos...')"
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="setZonas"
    @didDismiss="setZonas = false"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="setZonas = false">{{
            $t('Cancelar')
          }}</ion-button>
        </ion-buttons>
        <ion-title>{{ $t('Seleccionar zonas') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="setIncidenceZonas">{{ $t('Seleccionar') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>


    <ion-content class="ion-padding" style="height:calc(100% - 80px)">
    <ion-searchbar
        class="defaultSearchBar"
        :placeholder="$t('Buscar zonas...')"
        v-model="zonasSearch"
        @ionInput="applyFiltersZonas(true)"
        :debounce="1000"
      />

      <ion-list style="border-radius: 15px" lines='full'>
        <template v-if="zonas?.flat()?.length">
          <ion-item
            v-for="(zona, key) in zonas?.flat()"
            :key="`modal-zona-${key}`"
          >
            <ion-checkbox slot="start" v-model="zona.active" label-placement='end'>{{ $t(`${zona?.nombre}`) }}</ion-checkbox>
          </ion-item>
        </template>
      </ion-list>
      <ion-infinite-scroll
        threshold="100px"
        id="infinite-scroll"
        @ionInfinite="onLoadZonas($event)"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('Cargando más zonas...')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

</template>

<script>
import {
  IonModal,
  IonButton,
  IonSearchbar,
  IonList,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLabel,
  IonInput,
  IonItem,
  IonTextarea,
  IonContent,
  IonIcon,
  toastController,
  loadingController,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';
import { informationCircle, camera, imagesOutline, qrCodeOutline } from 'ionicons/icons';
import SystemTree from '@/components/TreeView/SystemTree.vue';

import { ref, getCurrentInstance } from 'vue';
import { useGmaoStore } from '@/stores/gmao';
import { useWorkOrdersStore } from '@/stores/workorders';

// SQLite
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';

import { useOfflineStore } from '@/stores/offline';

export default {
  name: 'NewIncidence',
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    // addresses: {
    //   type: Object,
    // },

    customAddress: {
      type: Object,
    },

    sistemaId: {
      type: Number,
    },

    item: {
      type: Object,
    },

    anomaliesAssets: {
      type: Array,
    },

    isWorkorder: {
      type: Boolean,
      default: true
    },

    searchAddresses: {
      type: Boolean,
    }
  },

  emits: [
    'done',
  ],

  components: {
    IonModal,
    IonButton,
    IonSearchbar,
    IonList,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonLabel,
    IonInput,
    IonItem,
    IonTextarea,
    IonContent,
    IonIcon,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    SystemTree,
  },

  computed: {
    computedAddresses() {
      return this.addresses?.flat()?.filter((a) =>
        Object.values({
            direccion: a?.direccion,
            nombre: a?.nombre,
            cp: a?.cp,
            cliente: a.cliente?.nombre,
            })
          .join(';')
          ?.toLowerCase()
          .includes((this.addressSearch || '').toLowerCase())
      );
    },
    computedSistemas() {
      return this.sistemas.filter((a) =>
        Object.values(a)
          .join(';')
          ?.toLowerCase()
          .includes((this.sistemaSearchAsset || '').toLowerCase())
      );
    },
  },

  watch: {
    async modelValue(v) {
      if (v) {

        await this.applyFiltersDirecciones(true);

        this.getTiposIncidencia();
        if (!this.item) this.getSistemas();
        // if (!this.item) this.applyFiltersSistemas(true);
        this.applyFiltersZonas(true);
        // Sin direccion pero con activo
        if(!this.newIncidence.id_direccion && this.item) {
          this.newIncidence.id_direccion = this.item?.sistema?.id_direccion;
          this.newIncidence.address = this.item?.sistema?.direccion;
          this.newIncidence.id_sistema = this.item?.sistema?.id;
          this.newIncidence.sistema = this.item?.sistema;

          this.applyFiltersMaquinas(true)
        }

        // Direccion por cliente
        // if (this.customAddress?.id) this.defaultAddress = this.customAddress;
        
        this.newIncidence.address = this.defaultAddress;
        this.newIncidence.id_direccion = this.defaultAddress.id;
      }
    }
  },

  setup() {
    const gmao = useGmaoStore();
    const wo = useWorkOrdersStore();

    const offline = useOfflineStore();
    const app = getCurrentInstance();

    return {
      wo,
      gmao,

      app,
      offline,

      camera,
      imagesOutline,
      qrCodeOutline,
      setAddress: ref(false),
      addressSearch: ref(null),
      newIncidence: ref({}),
      incidenceTypes: ref([]),
      gamas: ref([]),
      sistemas: ref([]),
      maquinas: ref([]),

      priorities: ref([
        { value: 5, label: 'Normal' },
        { value: 4, label: 'Media' },
        { value: 3, label: 'Alta' },
        { value: 2, label: 'Muy alta' },
        { value: 1, label: 'Urgente' },
      ]),

      setGama: ref(false),
      setSubGama: ref(false),
      setIncidenceSistema: ref(false),
      setIncidenceMaquina: ref(false),

      especialidadSearch: ref(null),
      subEspecialidadSearch: ref(null),
      sistemaSearchAsset: ref(null),
      activeSearchAsset: ref(null),

      zonasSearch: ref(null),
      setZonas: ref(false),
      zonas: ref([]),

      addressSearchAjax: ref(null),
      addresses: ref([]),
      defaultAddress: ref({}),

      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      current_page_addresses: ref(1),
      last_page_addresses: ref(null),
      total_addresses: ref(null),
      loading: ref(false),
    };
  },

  methods: {
    getSearchAddresses(reset = true) {
      if(reset) this.addresses = [];
      // this.getDirecciones(this.addressSearchAjax);
      this.applyFiltersDirecciones(true);
    },

    onLoadDirecciones(event) {
      if (!this.loading && this.current_page_addresses < this.last_page_addresses) {
        this.current_page_addresses += 1;
        this.applyFiltersDirecciones(false).then(() => {
          event.target.complete();
        });
      } else event.target.complete();
    },

    async applyFiltersDirecciones(reset = true) {
      if (reset) {
        this.addresses = [];
        this.current_page_addresses = 1;
      }
      return await this.getDirecciones();
    },

    async getDirecciones() {
      
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getDireccionesTecnico',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
            page: this.current_page_addresses,
            search: this.addressSearchAjax
          },
        })
        .then(({ data }) => {
          this.addresses.push(data.data);
          
          this.current_page_addresses = data.current_page;
          this.last_page_addresses = data.last_page;
          this.total_addresses = data.total;
          
          if (this.addresses.flat().length === 1) {
            const [firstAddress] = this.addresses.flat();
            this.defaultAddress = firstAddress;
          }
        });
    },

    setIncidenceZonas() {
      this.newIncidence.zonas = this.zonas?.flat()?.filter((a) => a?.active);
      this.setZonas = false;
    },

    onLoadZonas(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersZonas(false).then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },

    applyFiltersZonas(reset = true) {
      if (reset) {
        this.zonas = [];
        this.current_page = 1;
      }
      return this.getZonas(this.current_page);
    },

    async getZonas() {
      return await this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getZonas',
            token: this.gmao.user.token,
            search: this.zonasSearch,
            page: this.current_page,
            v2: 1,
          },
        })
        .then(({ data }) => {

          this.zonas.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        });
    },

    setIncidenceActives() {
      this.newIncidence.maquinas = this.maquinas.flat().filter((m) => m.active);
      this.setIncidenceMaquina = false;
    },

    onLoadMaquinas(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersMaquinas(false).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFiltersMaquinas(reset = true) {
      if (reset) {
        this.maquinas = [];
        // this.wo.workorders = [];
        this.current_page = 1;
      }
      return this.getMaquinasSistema();
    },

    getMaquinasSistema() {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getMaquinasSistema',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            sistema: this.newIncidence?.sistema?.id,
            page: this.current_page,
            searchAsset: this.activeSearchAsset,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.maquinas.push(data.data);
          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        });
    },

    onLoadSistemas(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersSistemas(false).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFiltersSistemas(reset = true) {
      if (reset) {
        this.sistemas = [];
        // this.wo.workorders = [];
        this.current_page = 1;
      }
      return this.getSistemas();
    },

    getSistemas() {
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getWorkorderSistemas',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            direccion: this.newIncidence?.id_direccion || this.newIncidence?.address?.id || null,
            page: this.current_page,
            search: this.sistemaSearchAsset,
            v2: 1,
          },
        })
        .then(({ data }) => {
          // const systemas = this.getAllSistemas(data.data);

          // systemas.sort((a, b) => {
          //   return a.id < b.id ? -1 : 1;
          // });

          // this.sistemas.push(åsystemas);

          // this.current_page = data.current_page;
          // this.last_page = data.last_page;
          // this.total = data.total;
          if (this.$appVersion() > 148) this.sistemas = data;
          else this.sistemas.push(data.data);
          return data;
        });
    },

    getTiposIncidencia() {
      this.$axios
      .get('/v2/users/actions.php', {
          params: {
            call: 'getIncidenciaTipos',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
          },
        })
        .then(({ data }) => {
          this.incidenceTypes = data;
        });
    },

    onLoadGamas(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFiltersGamas(false).then(() => {
          event.target.complete();
        });
      } else {
        // event.target.disabled = true;
        event.target.complete();
      }
    },

    applyFiltersGamas(reset = true, gama = null) {
      if (reset) {
        this.gamas = [];
        // this.wo.workorders = [];
        this.current_page = 1;
      }
      return this.getEspecialidad(gama);
    },

    async getEspecialidad(value) {
      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getGamas',
            token: this.gmao.user.token,
            id_gama: value,
            page: this.current_page,
            search: this.especialidadSearch || this.subEspecialidadSearch,
            v2: 1,
          },
        })
        .then(({ data }) => {

          this.gamas.push(data.data);
          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
          return data;
        })
    },

    async galleryPicker() {
      const photos = await this.$Camera.pickImages();

      if (photos) this.uploadPhotoIncidence(photos.photos);
    },

    async addPhotoIncidence() {
      console.log('ADD PHOTO');

      try {
        const photo = await this.$Camera.getPhoto({
          resultType: this.$CameraResultType.Base64,
          source: this.$CameraSource.Camera,
          quality: 100,
        });

        console.log('PHOTO', photo);

        if (photo) {
          this.uploadPhotoIncidence([photo]);
        }
      } catch (error) {
        this.openToastOptions(
          this.$t('algo-no-ha-ido-bien'),
          this.$t('error-al-abrir-camara')
        );
        console.log(error);
      }
    },

    async uploadPhotoIncidence(photos) {
      const loading = await loadingController.create({
        message: 'Subiendo imágenes...'
      });

      await loading.present();

      this.newIncidence.blobs = [];
      this.newIncidence.images = [];

      const promises = photos.map(async (photo) => {
        photo.base64String =
          photo.base64String ||
          (await this.$getBase64Image(photo.webPath).then(
            (data) => data.split`,`[1]
          ));
        const blob = await new Promise((resolve, reject) => {
          this.$compressImage(this.$base64toBlob(photo.base64String), resolve, reject);
        });
        this.newIncidence.blobs.push(blob);
        this.newIncidence.images.push(
          `data:image/png;base64,${photo.base64String}`
        );

        return new Promise((resolve) => { resolve() });
      });

      Promise.all(promises).finally(async () => {
        await loading.dismiss();
      });
    },

    getAllSistemas(sistemas, level = 0, parent = '') {
      sistemas?.forEach((ss) => {
        ss.level = level;
        ss.name = level && (!+this.gmao.comportamientos?.gmao_interno || +this.gmao.comportamientos?.ver_direccion_en_instalacion) ?
          `${parent} - ${ss?.nombre}` : (ss.id != 1) ? `> ${ss?.nombre}` : ss?.nombre;
        if (ss.all_sistemas?.length) {
          sistemas = sistemas.concat(
            this.getAllSistemas(ss.all_sistemas, level + 1, ss.name)
          );
        }
      });

      return sistemas;
    },

    createIncidence(incData) {
      console.log('INC', incData);
      const error_keys = [];
      if (!this.isWorkorder && !incData?.address?.id && !this.gmao.comportamientos.gmao_interno) {
        error_keys.push(this.$t('Dirección'));
      }
      if (
        !this.newIncidence?.maquinas?.length
        && !incData?.maquinas?.length
        && +this.gmao.comportamientos.asset_mandatory_incidence_modal_tecs
      ) {
        error_keys.push(this.$t('Activo'));
      }
      if (
        !this.newIncidence?.gama?.id
        && !incData?.gama?.id
        && +this.gmao.comportamientos.speciality_mandatory_incidence_modal_tecs
      ) {
        error_keys.push(this.$t('Especialidad'));
      }

      if (error_keys.length) {
        this.openToastOptions(
          this.$t('algo-no-ha-ido-bien'),
          this.$tc('these_inputs_are_mandatory', [error_keys.map(a => a.toLowerCase()).toString()])
        );
        return;
      }
      
      const formData = new FormData();
      formData.append('id_tecnico', this.gmao.user.id);
      // formData.append('id_tipo', incData.id_tipo || '');
      formData.append('id_gama', incData.gama?.id || '');
      formData.append('id_subgama', incData.subgama?.id || '');
      formData.append('descripcion', incData?.problema || '');
      formData.append('prioridad', incData?.prioridad || '');

      formData.append('id_sistema', incData.sistema?.id || this.sistemaId || '');
      formData.append('maquinas', (incData.maquinas || [])?.map((m) => m.id).toString() || '');
      formData.append('zonas', (incData?.zonas || [])?.map((m) => m.id).toString() || '');
      formData.append('maquina_anomalias', this.anomaliesAssets?.map((m) => `${m.id}-${m?.anomalia?.id}` || '').toString() || '');

      formData.append('id_direccion', +this.gmao.comportamientos?.gmao_interno ? 1 : (incData.address?.id || this.customAddress?.id || ''));
      formData.append('id_cliente',incData.address?.id_cliente || this.customAddress?.id_cliente || '');
      
      // SI HAY ITEM POR QR
      if(this.item) {
        if(this.item?.id_sistema) formData.set('id_sistema', this.item?.id_sistema);
        if(this.item?.sistema?.id_direccion) formData.set('id_direccion', this.item?.sistema?.id_direccion);
        if(this.item?.sistema?.direccion?.id_cliente) formData.set('id_cliente', this.item?.sistema?.direccion?.id_cliente);

        formData.set('maquina', this.item?.id);
      }

      this.newIncidence.blobs?.forEach((img, index) => {
        formData.append(`blob${index}`, img);
      });

      this.$axios
        .post(
          `/v2/users/actions.php?call=setIncidencia&token=${this.gmao.user.token}&v2=1`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        .then(({ data }) => {
          this.$emit('done', data);
          this.$emit('update:modelValue', false);
          this.$emit('goBack');
        }).catch(async (e) => {
          // TODO: offline
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }
              await db.open();

              const syncObject = {
                callFunction: 'setIncidencia',
                data: {
                  incData,
                  customAddress: this?.customAddress,
                  id_tecnico: this.gmao.user?.id,
                  images: this.newIncidence?.images
                }
              };

              if (Object.entries(incData).length) {
                const incidencia_id = this.$moment().unix();
                let queryIma = '';
                const values = {
                  id: incidencia_id,
                  id_estado: +this.gmao.user?.comportamientos?.incidencia_default || 1,
                  id_usuario: this.gmao.user?.id || 0,
                  id_sistema: this.sistemaId || 0,
                  id_cliente: incData?.address?.id_cliente || this.customAddress?.id_cliente,
                  id_direccion: incData?.address?.id || this.customAddress?.id,
                  descripcion: incData?.problema,
                  id_tipo: 1,
                  created_by: this.gmao.user?.id || 0,
                  fecha_aviso: this.$moment().format("YYYY-MM-DD"),
                  hora_aviso: this.$moment().format("H:mm"),
                  created_at: this.$moment().format("YYYY-MM-DD H:mm:ss"),
                  last_modified: this.$moment().format("YYYY-MM-DD H:mm:ss")
                }
                const query = `
                  INSERT INTO incidencias (id, syncObject, id_estado, id_usuario, id_cliente, id_direccion, descripcion, id_tipo, created_by, fecha_aviso, hora_aviso, created_at, last_modified)
                  VALUES (
                    ${values?.id},
                    '${JSON.stringify(syncObject)}',
                    ${values.id_estado},
                    ${values.id_usuario},
                    ${values.id_sistema},
                    ${values.id_cliente},
                    ${values.id_direccion},
                    '${values.descripcion || ''}',
                    ${values.id_tipo},
                    ${values.created_by || 0},
                    '${values.fecha_aviso}',
                    '${values.hora_aviso}',
                    '${values.created_at}',
                    '${values.last_modified}'
                  );
                  SELECT * FROM incidencias WHERE id=last_insert_rowid();
                `;

                if (this.newIncidence?.images) {
                  this.newIncidence?.images.forEach((img, i) => {
                    queryIma += `
                      INSERT INTO incidencia_imagenes
                        (id, id_incidencia, id_usuario, base64Src, created_at, updated_at, last_modified)
                      VALUES
                        (
                          ${this.$moment().unix() + i + 1},
                          ${incidencia_id},
                          ${values.id_usuario},
                          '${img || ''}',
                          '${this.$moment().format("YYYY-MM-DD H:mm:ss")}',
                          '${this.$moment().format("YYYY-MM-DD H:mm:ss")}',
                          '${this.$moment().format("YYYY-MM-DD H:mm:ss")}'
                        );
                    `;

                  });
                }
                const setNewIncidence = new Promise((resolve) => {
                  resolve(this.$SQLiteQuery('createIncidence_new_workorder', 'incidencias', [query, queryIma], e.config, db));
                });
                setNewIncidence.then(() => {
                  setTimeout(() => {
                    sqlite.closeConnection('gmaoTecnicos').then(() => {
                      this.$emit('done', this.offline.incidence);
                      this.$emit('update:modelValue', false);
                    })
                  }, 500);
                });
              } else {
                throw Error('Algo no ha ido bien... NewIncidence');
              }

            } catch (error) {
              throw Error('NEW Incidence: ', error);
            }
          }
        })


    },
    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
  },
};
</script>
<style lang="scss" scoped>
ion-searchbar.defaultSearchBar {
  --background: #fff;
  --border-radius: 1rem;
}
</style>